import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../components';
import { sendRequest } from "../../utilities/api";

function GlobalTemplate(props) {
    const { id } = useParams();

    const [data, setData] = useState({ created_for: 'lead', type: 'sms', body: '', title: '' });
    const [loading, setLoading] = useState(true);
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [token, setToken] = useState('');
    const textareaRef = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            await getTemplateMeta();
            fetchRecordById('lead', 'sms');
        };
        fetchData();
    }, []);


    const getTemplateMeta = async () => {
        const formData = new FormData();
        formData.set('master_type', 'global');
        formData.set('from', 'calander');

        await sendRequest(`crm/templates/meta`, 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const fetchRecordById = (created_for, type) => {
        setLoading(true);

        sendRequest(`global/templates/${created_for}/${type}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`global/templates/update/${data.id}/${data.type}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    // props.navigate(`/template-manage/script/lists`);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const insertValueAtCursor = (valueToInsert) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = data.body.substring(0, start) + valueToInsert + data.body.substring(end);
        setData((prevState) => ({
            ...prevState,
            body: newText,
        }));

        // Set the cursor position after the inserted value
        textarea.selectionStart = start + valueToInsert.length;
        textarea.selectionEnd = start + valueToInsert.length;
        textarea.focus();
    };

    return (
        <Layout title="Global Templates" content="container-fluid">
            <div className="Global-template-lists">
                <Block.Head>
                    <Block.HeadContent>
                        <div className="d-flex justify-content-between">
                            <Block.Title tag="h2">Global Templates</Block.Title>

                            <button className="btn btn-primary d-md-inline-flex btn-sm" onClick={() => props.navigate(-1)}>
                                <span>Back</span>
                            </button>

                        </div>

                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Template For </Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Select
                                                        onChange={(e) => fetchRecordById(e.target.value, data.type)}
                                                        defaultValue={data.created_for} className="form-control" name="created_for">
                                                        <option value={'lead'}>Contact</option>
                                                        <option value={'user'}>User</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Template Type </Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Select onChange={(e) => fetchRecordById(data.created_for, e.target.value)} defaultValue={data.type} className="form-control" name="type">
                                                        <option value={'sms'}>SMS</option>
                                                        <option value={'email'}>Email</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control
                                                        value={data.title}
                                                        name="title"
                                                        onChange={handleChange}
                                                        placeholder="Enter Title"
                                                        required />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {
                                            !loading && (
                                                <div className="refresh-area">
                                                    {
                                                        (data.type == 'sms' || data.type == 'SMS') ? (
                                                            <div className="sms-body">
                                                                <Col md="6">
                                                                    <div className="token-button">
                                                                        <Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
                                                                            <option value=''>Select Token</option>
                                                                            <optgroup label="Extras Fields">
                                                                                {meta?.tokens?.extras && meta.tokens.extras.map((row, i) => {
                                                                                        return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                            <optgroup label="Default Fields">
                                                                                { meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
                                                                                        return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                            <optgroup label="Custom Fields">
                                                                                {
                                                                                    meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
                                                                                        return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        </Select>
                                                                        <Button size='sm' type="button" onClick={() => insertValueAtCursor(` ${token}`)} variant="outline-secondary">
                                                                            Insert Token
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Form.Group className="form-group mt-3">
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control
                                                                            ref={textareaRef}
                                                                            value={data.body}
                                                                            onChange={handleChange}
                                                                            as="textarea"
                                                                            name="body"
                                                                            placeholder="Enter body"
                                                                            required />
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        )
                                                            : (
                                                                <div className="email-body">
                                                                    <Col md="6">
                                                                        <Form.Group className="form-group">
                                                                            <Tinymce extraToken={meta?.tokens?.extras}
                                                                                leadToken={true} height={350} textareaName={'body'} toolbar={true} initialValue={data.body} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            )
                                        }
                                        <Col className="col-12">
                                            <Button className="btn-sm" type="submit">Update</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block >
            </div>
        </Layout >
    )
}

export default GlobalTemplate;