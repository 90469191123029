// ProtectedRoute.js
import React from 'react';
import { Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { validateTokenExpiration } from '../utilities';

function ProtectedRoute({ path, requiredPermission, element, isPublic = false }) {
    const { user, hasPermission } = useAuth();
    const navigate = useNavigate(); // Get the navigate function from react-router-dom
    const location = useLocation();
     // Get the navigate function from react-router-dom
    validateTokenExpiration();

    // ! user.has_completed_wizard && user.type != 'Reseller' && user.role_id != 1
    // commented this line below as per to skipped the wizard
    // if (user && location.pathname !== '/' && ! user.user.has_completed_wizard && user.user.type != 'Reseller' && user.user.role_id != 1) {
    //     return <Navigate to="/" />;
    // }
    
    if (!user  && !isPublic) {
        // If user is not logged in and the route is not public, navigate to login page
        return <Navigate to="/auths/auth-login" />;
    }

    if (!isPublic && requiredPermission && !hasPermission(requiredPermission)) {
        // If user doesn't have required permission and the route is not public, navigate to not found page
        return <Navigate to="/not-found" />;
    }

    return React.cloneElement(element, { location, navigate, key: location.pathname })
}

export default ProtectedRoute;