import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, ListGroup, Offcanvas } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, MediaGroup, MediaText } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";
import { toInitials, uInfo } from "../../../utilities";
import BasicLeadCreate from "../../../pages/crm-manage/leads/BasicLeadCreate";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_API_KEY } from "../../../store/site";
import CreateContact from "../../../pages/crm-manage/contacts/CreateContact";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LeadEdit(props) {
    let query = useQuery();

    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [oldValue, setOldValue] = useState({});
    const [customField, setCustomField] = useState([]);
    const [customFieldData, setCustomFieldData] = useState({});
    const [formloading, setFormLoading] = useState(false);
    const [currentType, setCurrentType] = useState('0');
    const [currentRecordType, setCurrentRecordType] = useState('0');

    const [primarydata, setPrimaryData] = useState({});
    const [primaryCurrentType, setPrimaryCurrentType] = useState('0');
    const [existingLead, setExistingLead] = useState([]);
    const [leadArrays, setLeadArrays] = useState([]);
    const [show, setShow] = useState(false);
    const [addressInfo, setAddressInfo] = useState({ city: '', state: '', zip: '' });

    useEffect(() => {

        const fetchData = async () => {
            await fetchMeta();
            await fetchRecordById();
        };
        fetchData();
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addNewALeadrray = (related_id, name) => {
        const newArray = [{ related_id: related_id, name: name }];

        setLeadArrays(newArray);

        setPrimaryData({ ...data, related_id: 0, lead_name: '' });
    };


    const unsetLeadArray = (related_id) => {
        const filteredArrays = leadArrays.filter(array => array.related_id !== related_id);
        setLeadArrays(filteredArrays);
    };

    const handlePrimarySubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('contact_type', 'primary');
        sendRequest('crm/leads/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    addNewALeadrray(response.data.lead_rec.id, response.data.lead_rec.name);
                    handleClose();
                }
                setFormLoading(false);
            })
    }

    const searchLead = (search) => {
        setPrimaryData({ ...data, related_id: 0, lead_name: `${search}` });
        if (search != "") {
            const formData = new FormData();
            formData.append('filter_value', search);
            formData.append('type', 'search');
            formData.append('lead_type', 'contact');
            sendRequest(`crm/leads/lists`, 'POST', formData).then(response => {
                if (response.status === true) {
                    setExistingLead(response.data.records);
                    if (response.data.records.length < 1) {
                    }
                }
            })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        } else {
            setExistingLead([]);
        }
    };

    const fetchMeta = async () => {
        const formData = new FormData();
        formData.set('type', 'default')
        await sendRequest('crm/leads/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setCustomField(response.data.customFields.custom_fields);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = async () => {
        await sendRequest(`crm/leads/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setCustomFieldData(response.data.list.custom_fields)
                setCurrentType(response.data.list.type)
                setCurrentRecordType(response.data.list.record_type)
                // setAddressInfo({ city: response.data.list.city, state: response.data.list.state, zip: response.data.list.zip })
                if (response.data.list.primaryContactData) {
                    addNewALeadrray(response.data.list.primaryContactData.id, response.data.list.primaryContactData.name);
                }
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleChange = (e, key) => {
        const { name, value, placeholder } = e.target;
        // setData((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        // }));       
    }

    const createUi = () => {

        return customField.map((elementSchema, elementIndex) => {
            const elementName = elementSchema.key;
            if (customField.hasOwnProperty(elementName) && !oldValue[elementName]) {
                setCustomFieldData((prevData) => ({
                    ...prevData,
                    [elementName]: oldValue[elementName],
                }));
            }

            if (elementSchema.type === 'select') {
                return (
                    <div className="col-md-6 mb-3" key={elementSchema.label}>
                        <label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                        <Form.Select defaultValue={customFieldData[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key}
                            id={elementSchema.key}>
                            <option
                                key={'-1'}
                                value={``}
                            >
                                Select Option
                            </option>
                            {elementSchema.options.map((row, i) => {
                                return (
                                    <option
                                        key={i}
                                        value={`${row.option}`}
                                    >
                                        {row.option}
                                    </option>
                                );
                            })}

                        </Form.Select>
                    </div>
                );
            } else if (elementSchema.type === 'radio') {
                return (
                    <div className="col-md-6 mb-3" key={elementSchema.label}>
                        <label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                        <br />
                        {elementSchema.options.map((row, i) => {
                            return (
                                <Form.Check
                                    key={i}
                                    inline
                                    type="radio"
                                    name={elementSchema.key}
                                    id={row.option}
                                    value={row.option}
                                    defaultChecked={customFieldData[elementSchema.key] === row.option}
                                    label={row.option}
                                    onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
                                />

                            );
                        })}

                    </div>
                );
            } else if (elementSchema.type === 'checkbox') {
                return (
                    <div className="col-md-6 mb-3" key={elementSchema.label}>
                        <div className="col-md-6 mb-3" key={elementSchema.label}>
                            <label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                            <br />
                            {elementSchema.options.map((row, i) => {
                                return (
                                    <Form.Check
                                        key={i}
                                        inline
                                        type="checkbox"
                                        name={elementSchema.key}
                                        value={row.option}
                                        id={row.option}
                                        defaultChecked={customFieldData[elementSchema.key] && customFieldData[elementSchema.key].includes(row.option)}
                                        label={row.option}
                                        onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
                                    />

                                );
                            })}
                        </div>
                    </div>
                );
            } else if (elementSchema.type === 'date') {
                return (
                    <div className="col-md-6 mb-3" key={elementSchema.label}>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control
                                    type="date"
                                    data-date=""
                                    data-date-format="DD MMMM YYYY"
                                    defaultValue={customFieldData[elementSchema.key] ? customFieldData[elementSchema.key].toString().split("/").reverse().join("-") : ''}
                                    //defaultValue={customFieldData[elementSchema.key]}
                                    onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key} />
                            </div>
                        </Form.Group>
                    </div>
                );
            } else {
                return (
                    <div className="col-md-6 mb-3" key={elementSchema.label}>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control defaultValue={customFieldData[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} type="text" name={elementSchema.key} />
                            </div>
                        </Form.Group>
                    </div>
                );
            }

        })
    }

    const handleChangeCustomField = (type, e) => {
        const { name, value } = e.target;

        if (type === "checkbox") {
            if (customFieldData[name] && customFieldData[name].includes(value)) {
                setCustomFieldData((prevState) => ({
                    ...prevState,
                    [name]: prevState[name].filter((color) => color !== value),
                }));
            } else {
                setCustomFieldData((prevState) => ({
                    ...prevState,
                    [name]: [...(prevState[name] || []), value],
                }));
            }
        } else if (type === "date") {
            setCustomFieldData((prevState) => ({
                ...prevState,
                [name]: value.toString().split("-").reverse().join("/"),
            }));
        } else {
            setCustomFieldData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    const handleSubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        const primary_contact = leadArrays[0] ? leadArrays[0].related_id : '';

        for (let [key, value] of formData.entries()) {
            if (!customField.some(item => item.key === key) && key != 'group_id[]' && key != 'tag_id[]') {
                submitData.append(key, value);
            }
        }
        submitData.append('id', id);


        // Object.entries(data).forEach(([key, value]) => {
        //     if(!['custom_fields','tag_id','linked_user_id','deal_id','status_id','group_id'].includes(key)){
        //         submitData.append(key, value);
        //     }
        // });

        submitData.append('group_id', formData.getAll('group_id[]'));
        submitData.append('tag_id', formData.getAll('tag_id[]'));

        submitData.append('custom_fields', JSON.stringify(customFieldData));

        submitData.append('primary_contact', primary_contact);

        sendRequest('crm/leads/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(-1);
                }
                setFormLoading(false);

            })
            .catch(error => {
                setFormLoading(false);
                // 
                console.error('POST request error:', error);
            });
    };

    const handlePlaceSelected = (place) => {
        const addressComponents = place.address_components;
        let address = {
            city: '',
            state: '',
            // country: '',
            zip: ''
        };

        addressComponents.forEach(component => {
            const types = component.types;
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.short_name;
            }
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zip = component.long_name;
            }
        });

        if (!address.zip && place.formatted_address) {
            const formattedAddressParts = place.formatted_address.split(',');
            const lastPart = formattedAddressParts[formattedAddressParts.length - 1];
            const zipMatch = lastPart.match(/\d{5}(-\d{4})?$/);
            if (zipMatch) {
                address.zip = zipMatch[0];
            }
        }

        setAddressInfo(address);

        console.log(address);
        console.log(addressInfo);
    };

    return (
        <Layout title="Client" content="container-fluid">
            <div className="Leads-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Clients</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/crm-manage/leads/lists">Clients</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md border-0">
                                <Card.Body className="py-2">
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3 mt-3">
                                                {/* <>
                                                    <Col className="col-6">
                                                        <div class="modal-title h4 mt-3">Edit Client</div>
                                                    </Col>
                                                    <Col className="col-6 text-end pe-0 mt-4 mb-3">
                                                        <Button size="sm" variant="primary" type="submit" disabled={formloading}>
                                                            {
                                                                formloading && (
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                )
                                                            }
                                                            <span >Edit</span>
                                                        </Button>
                                                        <Button variant="danger" type="button"
                                                            className="btn-sm ms-2 my-0"
                                                            onClick={() => props.navigate('/crm-manage/leads/lists')}
                                                        ><span >Cancel</span></Button>
                                                    </Col>
                                                </> */}
                                                <span className="border border-light rounded-1 mt-0">
                                                    <Row className="g-3 mt-0 mx-0">
                                                        <Col md="3">
                                                            <Form.Label htmlFor="type">Type</Form.Label>
                                                            <div>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Individual"
                                                                    name="type"
                                                                    value="0"
                                                                    checked={currentType == 0}
                                                                    onChange={(e) => setCurrentType(e.target.value)}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Business"
                                                                    name="type"
                                                                    value="1"
                                                                    checked={currentType == 1}
                                                                    onChange={(e) => setCurrentType(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col md="3">
                                                            <Form.Label htmlFor="type">Record Type</Form.Label>

                                                            <div>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Lead"
                                                                    name="record_type"
                                                                    value="0"
                                                                    checked={currentRecordType == 0}
                                                                    onChange={(e) => setCurrentRecordType(e.target.value)}
                                                                    required
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Client"
                                                                    name="record_type"
                                                                    value="1"
                                                                    checked={currentRecordType == 1}
                                                                    onChange={(e) => setCurrentRecordType(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="g-3 my-2 mx-0">
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="name">{currentType == '0' ? '' : 'Company'} Name</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.name} type="text" name="name" placeholder="Name" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.email} type="email" name="email" placeholder="Email" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        {currentType == '0' && (
                                                            <Col md="4">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="email">Date of Birth</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control type="date" defaultValue={data.date_of_birth} name="date_of_birth" placeholder="Date of Birth" />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Mobile">Mobile</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.mobile} type="number" name="mobile" placeholder="Mobile" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Phone">Phone</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.phone} type="number" name="phone" placeholder="Phone" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="role">Record Owner</Form.Label>
                                                                {meta?.coachPartners.length > 0 && (
                                                                    <Select name="coach_partner_id" defaultValue={data.coach_partner_id} removeItemButton>
                                                                        {
                                                                            meta?.coachPartners.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className="g-3  mx-0">
                                                        <Form.Control type="hidden" value={primarydata.contact_type} name="contact_type" required />
                                                        {(uInfo().id && primarydata.contact_type != 'new') ? (
                                                            <>
                                                                <Col md="4" className="mb-1">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="Phone">Primary Contact</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control type="text" size="sm" name="search_contact"
                                                                                placeholder="Search Contact..."
                                                                                value={primarydata.lead_name}
                                                                                onChange={(e) => searchLead(e.target.value)}
                                                                                readOnly={query.has('related_id')}
                                                                                onBlur={() =>
                                                                                    setTimeout(() => {
                                                                                        setExistingLead([]);
                                                                                    }, 1000)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Form.Group>

                                                                    {existingLead.length > 0 && (
                                                                        <ListGroup className="my-1">
                                                                            {existingLead.map((row, i) => {
                                                                                return (
                                                                                    <ListGroup.Item key={i} style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setData({ ...data, related_id: row.id, lead_name: `${row.id}# ${row.first_name} ${row.last_name}` })
                                                                                        addNewALeadrray(row.id, `${row.id}#${row.first_name} ${row.last_name}`);
                                                                                    }
                                                                                    } >
                                                                                        <MediaGroup>
                                                                                            <MediaText>
                                                                                                <div className="lead-text">{row.first_name} {row.last_name}</div>
                                                                                                <span className="sub-text">{row.email}</span>
                                                                                            </MediaText>
                                                                                        </MediaGroup>
                                                                                    </ListGroup.Item>
                                                                                );
                                                                            })}
                                                                        </ListGroup>
                                                                    )}
                                                                </Col>
                                                                <Col md="4" className="mb-1">
                                                                    <Form.Group className={`form-group ${query.has('related_id') && ('d-none')}`}>
                                                                        <Form.Label htmlFor="Phone"></Form.Label>
                                                                        <div className="form-control-wrap my-1">
                                                                            <button type="button" onClick={handleShow} className="btn btn-sm btn-primary">Add new</button>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            <>

                                                            </>
                                                        )}
                                                        {(uInfo().id && data.contact_type != 'new') && (
                                                            <>
                                                                <ul className="nk-list-option pt-0 w-100 text-start align-items-start">
                                                                    <li>
                                                                        <div className="mt-md-0 text-start">
                                                                            <ul className="d-flex flex-wrap  gx-1">
                                                                                {leadArrays.map((row, index) => (
                                                                                    <li key={index} onClick={() => unsetLeadArray(row.related_id)} style={{ cursor: 'pointer', paddingTop: '0', paddingBottom: '10px' }}>
                                                                                        <span className="badge text-bg-secondary-soft"><Icon name={'cross-circle-fill'}
                                                                                        ></Icon> {row.name}</span>
                                                                                    </li>

                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        )}
                                                    </Row>


                                                </span>
                                                <span className="border border-light rounded-1 px-0">
                                                        <div className="SectionHeading">
                                                        <h4 class="bio-block-title">{currentType == '0' ? '' : 'Company'} Address</h4>
                                                        </div>
                                                    <Row className="g-3 my-0 mb-3 mx-0 px-2">
                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Address">Address</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Autocomplete
                                                                        className="form-control"
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        defaultValue={data.address}
                                                                        apiKey={GOOGLE_MAP_API_KEY}
                                                                        onPlaceSelected={handlePlaceSelected}
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="City">City</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={addressInfo.city ? addressInfo.city : data.city} type="City" name="city" placeholder="City" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="State">State</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={addressInfo.state ? addressInfo.state : data.state} type="State" name="state" placeholder="State" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Zip">Zip</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={addressInfo.zip ? addressInfo.zip : data.zip} type="Zip" name="zip" placeholder="Zip" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        {/* <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Address">Address</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.address} type="Address" name="address" placeholder="Address"  />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}

                                                    </Row>
                                                </span>

                                                <span className="border border-light rounded-1 px-0 CategoryService">
                                                    <div className="SectionHeading">                                                        
                                                        <h4 class="bio-block-title">Group & Tag Fields </h4>
                                                    </div>
                                                    <Row className="g-3 my-0 mb-3 mx-0 px-2">
                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="group_id">Group</Form.Label>
                                                                <div className="form-control-wrap">


                                                                    <Select removeItemButton name="group_id[]" multiple defaultValue={data.group_id} >
                                                                        <option value="">Select Group</option>
                                                                        {
                                                                            meta.groups && meta.groups.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.title}</option>
                                                                            })
                                                                        }
                                                                    </Select>


                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="tag_id">Tag</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Select removeItemButton name="tag_id[]" multiple defaultValue={data.tag_id} >
                                                                        <option value="">Select Tag</option>
                                                                        {
                                                                            meta.tags && meta.tags.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.title}</option>
                                                                            })
                                                                        }
                                                                    </Select>


                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </span>

                                                <span className="border border-light rounded-1 px-0">
                                                    <div className="SectionHeading">
                                                        <h4 class="bio-block-title">Social Links</h4>
                                                    </div>
                                                    <Row className="g-3 my-0 mb-3 mx-0 px-2">
                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Website">Website</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.website} type="url" name="website" placeholder="Website" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="linkedin">Linkedin</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.linkedin} type="url" name="linkedin" placeholder="linkedin" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </span>
                                                <span className="border border-light rounded-1 px-0">
                                                    <div className="SectionHeading">
                                                        <h4 class="bio-block-title">Custom Fields</h4>
                                                    </div>
                                                    <Row className="m-3 mx-0 px-2">
                                                        {
                                                            meta.customFields && (
                                                                createUi()
                                                            )
                                                        }
                                                    </Row>
                                                </span>

                                                <Row className="m-1">
                                                    <Col className="col-12 d-flex justify-content-end">
                                                        <Button size="sm" variant="primary" type="submit" disabled={formloading}>
                                                            {
                                                                formloading && (
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                )
                                                            }
                                                            <span>Edit</span>
                                                        </Button>
                                                        <Button variant="danger" type="button"
                                                            className="btn-sm ms-2"
                                                            onClick={() => props.navigate('/crm-manage/leads/lists')}
                                                        ><span>Cancel</span></Button>

                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>

                                        <CreateContact
                                            show={show}
                                            handleClose={handleClose}
                                            handlePrimarySubmit={handlePrimarySubmit}
                                            contactRelationsTypes={meta?.contactRelationsTypes}
                                        />

                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default LeadEdit;