import React, { useState, useEffect } from 'react';
import { Col, Button, Form, Nav, Tab, Container, Modal } from 'react-bootstrap';
import Email from './setting-manage/Email';
import { sendRequest } from '../utilities/api';
import CalendarCreate from './calendar-manage/Create';

import LocationCreate from './location-manage/Create';
import Swal from 'sweetalert2';

const SettingsWizard = () => {
    const [data, setData] = useState({});
    // as per client demand wizard is disabled to enable it just change it into true useState(true)
    const [isShow, setIsShow] = useState(false);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await getUserDetail();
        };

        const wizardSkipped = localStorage.getItem('wizardSkipped');
        if (wizardSkipped) {
            setIsShow(false);
        }

        fetchData();
    }, []);

    const getUserDetail = async () => {
        await sendRequest(`users/User/0?wizad_details=true`, 'POST')
            .then(response => {
                if (response.status === true) {
                    const userData = response.data.list;
                    setData(userData);

                    // Determine required steps
                    const requiredSteps = determineRequiredSteps(userData);
                    setSteps(requiredSteps);

                    if (requiredSteps.length === 0) {
                        handleWizardComplete();
                    } else {
                        setCurrentStep(requiredSteps[0].key);
                    }
                }
            })
            .catch(error => console.error('POST request error:', error));
    };

    const determineRequiredSteps = (record) => {
        const stepsList = [];

        if (!record.has_emailsettings) {
            stepsList.push({ key: 'step2', label: 'Email Service Settings', component: <Email fullPageView={true} getUserDetail={getUserDetail} /> });
        }
        if (!record.has_calendar) {
            stepsList.push({ key: 'step3', label: 'Calendar', component: <CalendarCreate fullPageView={true} getUserDetail={getUserDetail} /> });
        }
        if (!record.has_location) {
            stepsList.push({ key: 'step4', label: 'Location', component: <LocationCreate fullPageView={true} getUserDetail={getUserDetail} /> });
        }

        return stepsList;
    };

    const handleWizardComplete = () => {
        const tokenString = localStorage.getItem('authToken');
        let token = JSON.parse(tokenString);

        if (token && token.user) {
            token.user.has_completed_wizard = true;
            localStorage.setItem('authToken', JSON.stringify(token));
        }

        Swal.fire({
            title: 'Success',
            text: 'You successfully completed the initial setup',
            icon: 'success',
            confirmButtonText: 'OK',
        }).then(() => {
            setIsShow(false);
            window.location.href = '/';
        });
    };

    const handleNextStep = () => {
        const currentIndex = steps.findIndex(step => step.key === currentStep);
        if (currentIndex < steps.length - 1) {
            setCurrentStep(steps[currentIndex + 1].key);
        } else {
            // If last step is completed, close wizard and show success message
            handleWizardComplete();
        }
    };

    const handlePrevStep = () => {
        const currentIndex = steps.findIndex(step => step.key === currentStep);
        if (currentIndex > 0) {
            setCurrentStep(steps[currentIndex - 1].key);
        }
    };

    return (
        <Modal size="xl" show={isShow} onHide={() => {}}>
            <Modal.Header>
                <Modal.Title className="event-title" as="h4">Complete Settings Wizard</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '80vh', overflow: 'auto' }}>
                <Container className="mt-3 SettingsWizard">
                    <Tab.Container activeKey={currentStep}>
                        <Nav variant="pills" className="justify-content-center mb-3">
                            {steps.map(step => (
                                <Nav.Item key={step.key}>
                                    <Nav.Link
                                        eventKey={step.key}
                                        onClick={() => setCurrentStep(step.key)}
                                        className={`wizard-step ${currentStep === step.key ? 'active' : ''}`}
                                    >
                                        {step.label}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <hr className="my-0" />
                        <Tab.Content>
                            {steps.map(step => (
                                <Tab.Pane key={step.key} eventKey={step.key} className="p-5 bg-white rounded">
                                    {step.component}
                                    <div className="mt-3">
                                        {steps.length > 1 && (
                                            <>
                                                {currentStep !== steps[0].key && (
                                                    <Button variant="secondary" className="wizard-btn me-3" onClick={handlePrevStep}>Previous</Button>
                                                )}
                                                {currentStep !== steps[steps.length - 1].key && (
                                                    <Button variant="primary" className="wizard-btn" onClick={handleNextStep}>Next</Button>
                                                )}
                                            </>
                                        )}
                                        {steps.length === 1 && (
                                            <Button variant="primary" className="wizard-btn" onClick={handleWizardComplete}>
                                                Complete Setup
                                            </Button>
                                        )}
                                    </div>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        localStorage.setItem('wizardSkipped', 'true');
                        setIsShow(false);
                    }}
                >
                    Skip Wizard
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsWizard;
