const HostName = (window.location.host == '192.168.5.129:5001' || window.location.host == 'localhost:5001') ?
    'http://192.168.5.55/10xcrm/api/' :
    "https://ws.10xcrm.com/api/";

export const ApiUrl = HostName;


const HostWebUrl = (window.location.host == '192.168.5.129:5001'  || window.location.host == 'localhost:5001') ?
    'http://localhost:5001' :
    "https://newapp.10xcrm.com";
export const HostUrl = HostWebUrl;