import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";

function ScriptTemplateCreate(props) { 


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`templates/script/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(`/template-manage/script/lists`);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Script" content="container-fluid">
            <div className="script-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Script</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={`/template-manage/script/lists`}>Script</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Tinymce leadToken={true} height={700} initialValue={''} textareaName={'body'} toolbar={true} />
                                            </Form.Group>
                                        </Col>


                                        <Col className="col-12">
                                            <Button className="btn-sm" type="submit">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default ScriptTemplateCreate;