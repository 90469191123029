import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Table } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../../../components';
import { sendRequest } from "../../../../utilities/api";
import $ from "jquery";
import { currencySign } from "../../../../utilities";
window.jQuery = $;
window.$ = $;

require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')

function FormEdit(props) {
    const { id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [], groups: [], checkouts: [], disclaimers: [] });
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            fetchRecordById();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        const formData = new FormData();
        await sendRequest('crm/leads/forms/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const updateFormBuilder = (data) => {
        const formData = new FormData();
        formData.append('metadata', data);
        formData.append('id', id);
        sendRequest(`crm/leads/forms/updateMetadata/${id}`, 'POST', formData)
            .then(response => {
                $('.fb-editor').empty();
                $('.fb-rendered-form').empty();
                fetchRecordById();
                setActiveStep(2);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }
    const fetchRecordById = () => {
        sendRequest(`crm/leads/forms/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }
    const loadEditor = () => {
        setIsInitialize(true);
        if (!isInitialize) {

            var fields = [{
                label: "Signature",
                type: "textarea",
                subtype: "signature",
                value: false,
                icon: "🖊️",
                name: "signature-" + new Date().getTime()
            }];
            var $fbEditor = $(document.getElementById('fb-editor')),
                $formContainer = $(document.getElementById('fb-rendered-form')),
                fbOptions = {
                    formData: data.metadata,
                    onSave: function () {
                        $formContainer.toggle();
                        updateFormBuilder(formBuilder.formData);
                        $('form', $formContainer).formRender({
                            formData: formBuilder.formData
                        });
                    },
                    disabledAttrs: ["value", "placeholder", "access", "name"],
                    disabledActionButtons: ['data'],
                    disableFields: ['autocomplete', 'file', 'hidden'],
                    subtypes: {
                        textarea: ['signature'],
                    },
                    disabledSubtypes: {
                        textarea: ['tinymce', 'quill'],
                    },
                    typeUserDisabledAttrs: {
                        'textarea': [
                            'subtype', 'maxlength', 'rows', 'value'
                        ]
                    },
                    inputSets: [
                        {
                            label: 'Contact Info',
                            name: 'info',
                            // showHeader: true,
                            icon: "👤",
                            fields: [
                                {
                                    type: 'text',
                                    label: 'First Name',
                                    className: 'form-control',
                                    name: "firstName-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'Last Name',
                                    className: 'form-control',
                                    name: "lastName-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'Email',
                                    className: 'form-control',
                                    name: "email-" + new Date().getTime(),
                                    subtype: "email",
                                },
                                {
                                    type: 'text',
                                    label: 'Phone',
                                    className: 'form-control',
                                    name: "phone-" + new Date().getTime()
                                }
                            ]
                        },
                        {
                            label: 'Address',
                            name: 'address',
                            // showHeader: true,
                            icon: "📬",
                            fields: [
                                {
                                    type: 'text',
                                    label: 'Address',
                                    className: 'form-control',
                                    name: "address-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'Address Line2',
                                    className: 'form-control',
                                    name: "address2-" + new Date().getTime()

                                },
                                {
                                    type: 'text',
                                    label: 'City',
                                    className: 'form-control',
                                    name: "city-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'State',
                                    className: 'form-control',
                                    name: "state-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'Zip',
                                    className: 'form-control',
                                    name: "zip-" + new Date().getTime()
                                },
                                {
                                    type: 'text',
                                    label: 'Country',
                                    className: 'form-control',
                                    name: "country-" + new Date().getTime()
                                },
                            ]
                        }

                    ],
                    fields,
                },
                formBuilder = $fbEditor.formBuilder(fbOptions);


        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (activeStep == 0) {
            loadEditor();

            setTimeout(() => {
                $('.save-template').text('Next')
            }, 100);

            setActiveStep(1);
        }
        else if (activeStep == 1) {
            setActiveStep(2);
        } else if (activeStep == 2) {

            const submitData = new FormData();
            for (let [key, value] of formData.entries()) {
                submitData.append(key, value);
            }
            console.log(submitData, 'submitData')
            sendRequest(`crm/leads/forms/update/${id}`, 'POST', submitData)
                .then(response => {
                    if (response.status === true) {
                        props.navigate('/crm-manage/leads/forms/lists');
                    }
                    setLoading(true);
                })
                .catch(error => {
                    setLoading(true);
                    console.error('POST request error:', error);
                });
        }
    };

    const steps = ['Basic Infromation', 'Build Form', 'Map Fields'];

    return (
        <Layout title="Forms Builder" content="container-fluid CategoryService">
            <div className="forms-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Build Form</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/crm-manage/leads/forms/lists">Form List</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">

                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3 mb-3">
                                                {steps.map((step, index) => (
                                                    <div
                                                        key={step}
                                                        className={`step ${activeStep === index ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setActiveStep(index);
                                                            index == 1 && loadEditor();
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <span className="step-count">{index + 1}</span>
                                                        <p className="step-name">{step}</p>
                                                    </div>
                                                ))}
                                            </Row>

                                            <div className={`bio-block ${activeStep == 0 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={data.name} type="text" name="name" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Groups</Form.Label>
                                                            {meta?.groups.length > 0 ? (
                                                                <Select defaultValue={data.group_id} name="group_id[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta?.groups.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) : (
                                                                <Select name="group_id[]">
                                                                    <option value="">Select</option>
                                                                </Select>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Assign To</Form.Label>
                                                            {meta?.coachPartners.length > 0 ? (
                                                                <Select defaultValue={data.permissions} name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.coachPartners.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Status</Form.Label>
                                                            <Select name="status" defaultValue={data.status}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Next</Button>
                                                    </Col>

                                                </Row>
                                            </div>

                                            <div className={`bio-block ${activeStep == 1 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <div className="fb-editor" id="fb-editor"></div>
                                                </Row>
                                            </div>

                                            <div className={`bio-block ${activeStep == 2 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <Col className="col-12">
                                                        <div className="border py-3 px-3">
                                                            <div className="d-flex space-between">
                                                                <Table className="table table-light mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Label</th>
                                                                            {/* <th>Index</th> */}
                                                                            <th>System Map Columns</th>
                                                                        </tr>
                                                                        {
                                                                            data.metadata && data.metadata.map((row, index) => {
                                                                                let label = row.label.replace(/(<([^>]+)>)/gi, "");
                                                                                var pattern = /^signature-\d+$/;
                                                                                if (row.name && !row.name.match(pattern)) {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{label.length > 30 ? label.substring(0, 30) + '...' : label}</td>
                                                                                            {/* <td>{row.name}</td> */}
                                                                                            <td>
                                                                                                <Select
                                                                                                    type="select"
                                                                                                    name={`fields[${row.name}]`}
                                                                                                    value={data.mapped_data[row.name]}
                                                                                                    defaultValue={data.mapped_data[row.name]}
                                                                                                    at={row.label}
                                                                                                    a={index}
                                                                                                >
                                                                                                    <option value="">Map Column</option>
                                                                                                    <optgroup label="Default Fields">
                                                                                                        {data.fields.default_fields.map((column, c) => {
                                                                                                            return (
                                                                                                                <option a={column.key}
                                                                                                                    value={`${column.key}`}>{column.label}</option>
                                                                                                            )
                                                                                                        })}
                                                                                                    </optgroup>
                                                                                                    <optgroup label="Custom Fields">
                                                                                                        {data.fields.custom_fields.map((column, c) => {
                                                                                                            return (
                                                                                                                <option a={column.key}
                                                                                                                    value={`${column.key}`}>{column.label}</option>
                                                                                                            )
                                                                                                        })}
                                                                                                    </optgroup>
                                                                                                </Select>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </thead>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Disclaimer</Form.Label>
                                                            {meta?.disclaimers.length > 0 ? (
                                                                <Select defaultValue={data.disclaimer} name="disclaimer">
                                                                    {
                                                                        meta.disclaimers.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title} | {row.sub_type}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) : (
                                                                <Select name="disclaimer"  >
                                                                    <option value="">Select</option>
                                                                </Select>
                                                            )
                                                            }


                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Checkout Link</Form.Label>
                                                            {meta?.checkouts.length > 0 ? (
                                                                <Select defaultValue={data.product_id} name="product_id" removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.checkouts.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title} | {currencySign}{row.amount_formated}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="product_id" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-12 text-end">
                                                        <Button className="mr-5 btn-sm" type="button" onClick={() => { setActiveStep(activeStep - 1); loadEditor(); }}>Prev</Button>
                                                        <Button type="submit" className="btn-sm">Update</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default FormEdit;