import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from '../../../../utilities/api';
import { Icon, LinkList, LinkListItem, Select } from "../../../../components";
import { useAuth } from '../../../../AuthContext';
import DataTableComponent from '../../../../components/DataTable/DataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';

function LeadFunnelsList(props) {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);
	const [clientForm, setClientForm] = useState(false);
	const [modalClone, setModalClone] = useState(false);
	const [modalDetail, setModalDetail] = useState({ modal: false, data: {} });
	const [tableHeaders, setTableHeaders] = useState([]);
	const formRef = useRef(null);
	const childRef = useRef(null);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};

	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to delete this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, clone it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`crm/leads/funnels/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};

	const userColumns = [
		{
			name: "Name",
			key: 'name',
			selector: (row) => row.name,
			cell: (row) => (
				<span>
					{
						hasPermission('editLeadsFunnels') ? (
							<Link to={`/crm-manage/leads/funnels/builder/${row.public_id}`}>
								{row.name}
							</Link>
						) : (
							row.name
						)
					}
				</span>
			),
		},

		{
			name: "Domain",
			key: 'domain_url',
			selector: (row) => row.domain_url,
			cell: (row) => (
				<span>
					<Link target='_blank' to={row.domain_url}>
						{row.domain_url}
					</Link>
				</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},
		{
			name: "Submissions",
			key: 'submission_count',
			selector: (row) => row.submission_count,
			cell: (row) => (
				<button className="btn p-0 btn-sm btn-link" onClick={() => { setModalClone(true); setClientForm(row); makeTableHeaders(row) }}>
					<b>{row.submission_count}</b>
				</button>
			),
		},
		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">

							{
								hasPermission('editLeadsFunnels') && (
									<button className="btn p-0" onClick={() => props.navigate(`/crm-manage/leads/funnels/edit/${row.public_id}`)}>
										<Icon name="edit"></Icon>
									</button>
								)
							}

							&nbsp; | &nbsp;

							<button className="btn p-0" onClick={() => { setModal(true); setClientForm(row) }}>
								<Icon name="link" className='text-primary'></Icon>
							</button>

							&nbsp; | &nbsp;

							{
								hasPermission('deleteLeadsFunnels') && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}

						</LinkList>
					</div>
				</div>
			),
		},

	];

	const openDetailModal = (row) => {
		row.datam = JSON.parse(row.data);
		setModalDetail({ modal: true, data: row });
	}

	const makeTableHeaders = (row) => {
		const headers = row.metadata ? JSON.parse(row.metadata).flat() : [];

		setTableHeaders(headers);
	}




	return (
		<Layout title="Funnels List" content="container-fluid">
			<div className="Leads">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Funnels List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/crm-manage/leads/funnels/lists">Funnels</Link></li>
									<li className="breadcrumb-item active" aria-current="page">List</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{
									hasPermission('createLeadsFunnels') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/crm-manage/leads/funnels/create">
												<Icon name="plus" />
												<span>Add Funnel</span>
											</Link>
										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range"
										>
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>

									<Col>
										<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
									</Col>

									<Col>
										<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
									</Col>

									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive LeadFunnelsListTable table-striped"
								slug={'crm/leads/funnels/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>

				<Modal show={modalClone} onHide={() => { setClientForm({}); setModalClone(false); }} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">Submitted Forms</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflow: 'auto' }}>
						<table className='table table-bordered table-striped table-hover '>
							<thead>
								<tr>
									{tableHeaders.map((dataItem, i) => (
										<th key={i} className='text-nowrap' style={{ minWidth: '150px' }}>{dataItem.label}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{clientForm?.meta && clientForm.meta.map((row, i) => {
									let parsedData;
									try {
										parsedData = JSON.parse(row.data);
									} catch (e) {
										console.error("Invalid JSON format:", row.data);
										parsedData = {};
									}
									return (
										<tr key={i}>
											{parsedData.formData ? (
												parsedData.formData.map((dataItem, j) => (
													<td key={j}>{dataItem.value}</td>
												))
											) : (
												<td>No data available</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>


					</Modal.Body>
					<Modal.Footer>
						<Button size={'sm'} variant="danger" onClick={() => { setClientForm({}); setModalClone(false); }}>Close</Button>
					</Modal.Footer>
				</Modal>


				<Modal show={modalDetail.modal} onHide={() => { setModalDetail({ modal: false, data: {} }) }} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">Form Submitted Data</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<table className='table table-bordered'>
							<tbody>

								{
									modalDetail.data?.datam && modalDetail.data?.datam?.formData.map((row, i) => {
										return (
											<tr key={i}>
												<td>{row.label}</td>
												<td>{row.value}</td>
											</tr>
										)
									})
								}

								{
									modalDetail.data?.datam && modalDetail.data?.datam?.signature && (
										<>
											{
												modalDetail.data?.datam?.signature?.signature_type == 'type_it' ? (
													<tr>
														<th colSpan="2">{modalDetail.data?.datam?.signature?.initial}</th>
													</tr>
												) : modalDetail.data?.datam?.signature?.signature_type == 'draw_it' ? (
													<tr>
														<td colSpan="2">
															<div className="card">
																<img className="card-img-top"
																	height={250}
																	src={modalDetail.data?.datam?.signature?.digital_signature_image_url}
																/>
															</div>
														</td>
													</tr>
												) : (
													<tr></tr>
												)
											}
										</>
									)
								}

								<tr>
									<td>Title</td>
									<td>{clientForm.name}</td>
								</tr>

								<tr>
									<td>IP Address	</td>
									<td>{modalDetail.data?.ip_address}</td>
								</tr>

								<tr>
									<td>Submitted Date	</td>
									<td>{modalDetail.data?.dated}</td>
								</tr>

							</tbody>
						</table>




					</Modal.Body>
					<Modal.Footer>
						<Button size={'sm'} variant="danger" onClick={() => { setModalDetail({ modal: false, data: {} }) }}>Close</Button>
					</Modal.Footer>

				</Modal>

				<Modal show={modal} onHide={() => setModal(false)} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">Funnel Form:{clientForm.name} Embed Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="url">
							<a
								href={`${clientForm.domain_url}`}
								target="_blank"
								className="pr-5 mr-5"
							>
								{clientForm.domain_url}
							</a>

							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="overlay-example">
										Copy Form url
									</Tooltip>
								}
							>
								<button
									className="btn btn-link btn-md"
									onClick={() => {
										navigator.clipboard.writeText(`${clientForm.domain_url}`);
									}}
								>
									<Icon name="copy"></Icon>
								</button>
							</OverlayTrigger>
						</div>

						<div className="url d-flex">
							<label className="pt-3">Iframe Embed Code </label>
							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="overlay-example">
										Copy iframe Embed Code
									</Tooltip>
								}
							>
								<button
									className="btn btn-link btn-md"
									onClick={() => {
										navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${clientForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
									}}
								>
									<Icon name="copy"></Icon>
								</button>
							</OverlayTrigger>

						</div>
						<textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${clientForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>

					</Modal.Body>
					<Modal.Footer>
						<Button size={'sm'} variant="danger" onClick={() => { setClientForm({}); setModal(false); }}>Close</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</Layout>
	)
}

export default LeadFunnelsList;
