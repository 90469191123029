import { useEffect, useState, useRef } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import ContactNav from '../nav/ContactsNav';
import Swal from 'sweetalert2';

function UserList() {
    const { hasPermission } = useAuth();
    const [modal, setModal] = useState(false);
    const [currentEditLabelIndex, setCurrentEditLabelIndex] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState(false);

    const [lables, setLables] = useState({
        "Tag": "Tag",
        "Group": "Group",
        "Service": "Service",
        "Category": "Category"
    });

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        const formData = new FormData();

        await sendRequest('groups/tags/meta', 'POST', formData).then(response => {
            if (response.status === true) {
                setLables(response.data)
            }
        })
    }

    const deleteRecord = async (id, type) => {

        sendRequest(`groups/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    switch (type) {
                        case 'Tag':
                            reloadDataTable('Tags');
                            break;
                        case 'Group':
                            reloadDataTable('Groups');
                            break;
                        case 'Service':
                            reloadDataTable('Service');
                            break;
                        case 'Category':
                            reloadDataTable('Category');
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });
    };

    const copyTag = async (id, type, section) => {

        if (section === 'Both' || section === 'Job') {
            Swal.fire({
                text: 'This Tag is already copied to Job section!',
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'Ok'
            })

            return
        }

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to copy selected Tag to Job section?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                const formData = new FormData();

                formData.append('selected_record', id);
                formData.append('action', 'copy_tags');

                sendRequest(`groups/field/update`, 'POST', formData).then(response => {
                    if (response.status === true) {
                        reloadDataTable('Tags');
                    }
                })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });
            }
        });
    }

    const removeTag = async (id, type, section) => {

        if (section === 'Contact') {
            Swal.fire({
                text: 'This Tag is already not assigned to Job section!',
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'Ok'
            })

            return
        }

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove selected Tag from Job section?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                const formData = new FormData();

                formData.append('selected_record', id);
                formData.append('action', 'remove_tags');

                sendRequest(`groups/field/update`, 'POST', formData).then(response => {
                    if (response.status === true) {
                        reloadDataTable('Tags');
                    }
                })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });
            }
        });
    }

    // const deleteRecord = async (id) => {
    // 	sendRequest(`groups/${id}`, 'DELETE', { id: id })
    // 		.then(response => {
    // 			if (response.status === true) {
    // 				if (childRef.current) {
    // 					childRef.current.handleFilter(new FormData());
    // 				}
    // 			}
    // 		})
    // 		.catch(error => {
    // 			console.error('Delete request error:', error);
    // 		});

    // };
    const getUsersColumns = (type = '') => {
        const userColumns = [
            {
                name: "Title",
                key: 'title',
                selector: (row) => row.name,
                cell: (row) => (
                    <>
                        {/* {row.type === 'Tag' ? ( */}
                        <span style={{ backgroundColor: row.bg_color, color: row.text_color, padding: '2px 5px', borderRadius: '5px' }}>
                            {hasPermission('editGroup') ? (
                                <Link style={{ color: row.text_color }} to={`/tags-manage/edit/${row.public_id}`}>
                                    {row.title}
                                </Link>
                            ) : (
                                row.title
                            )}
                        </span>
                        {/* ) : (
                            <span>
                                {hasPermission('editGroup') ? (
                                    <Link to={`/tags-manage/edit/${row.public_id}`}>
                                        {row.title}
                                    </Link>
                                ) : (
                                    row.title
                                )}
                            </span>
                        )} */}
                    </>
                ),
            },
            {
                name: <div className='w-100 text-center'>Status</div>,
                key: 'status',
                selector: (row) => row.status,
                cell: (row) => (
                    <span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
                        : row.status === "Pending" ? "warning-soft"
                            : row.status === "Inactive" ? "secondary-soft"
                                : "primary-soft"}`
                    }>
                        {row.status ? row.status : 'General'}
                    </span>
                ),
            },
            {
                name: "Created At",
                key: 'created_at',
                selector: (row) => row.created_at,
                cell: (row) => (
                    <span>{row.created_at}</span>
                ),
            },
            {
                name: "Action",
                key: 'action',
                cell: (row) => (
                    <div className="text-center w-100">
                        <LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">
                            {
                                hasPermission('deleteGroup') && row.can_delete == 1 && (
                                    <>
                                        <button className="btn p-0" onClick={() => deleteRecord(row.public_id, row.type)}>
                                            <Icon name="trash" className='text-danger'></Icon>
                                        </button>

                                        {type === 'Tag' && (
                                            <button title="Copy to Job section" className="btn p-0" onClick={() => copyTag(row.public_id, row.type, row.section)}>
                                                <Icon name="copy" className='text-info' ></Icon>
                                            </button>
                                        )}
                                    </>
                                )
                            }
                        </LinkList>
                    </div>
                ),
            },
        ];

        // Include belongsToSection column only if type is 'Tag'
        if (type === 'Tag') {
            userColumns.splice(2, 0, {
                name: "Related To",
                key: 'section',
                selector: (row) => row.section,
                cell: (row) => (
                    <>
                        {row.section === 'Both' ? (
                            <div>
                                <span className="badge badge-primary text-bg-secondary-soft">Contact</span>
                                <span className="badge badge-success text-bg-secondary-soft ms-1 position-relative">
                                    Job
                                    <button type="button" onClick={() => removeTag(row.public_id, row.type, row.section)} className="btn-close btn-close-danger end-0" aria-label="Close" style={{ width: '1px', margin: '0px 0px 0 6px', float: 'right', alignItems: 'center', height: '4px' }}></button>
                                </span>
                            </div>
                        ) : row.section === 'Job' ? (
                            <span className="badge badge-success text-bg-secondary-soft position-relative">
                                {row.section}
                                <button type="button" onClick={() => removeTag(row.public_id, row.type, row.section)} className="btn-close btn-close-danger end-0" aria-label="Close" style={{ width: '1px', margin: '0px 0px 0 6px', float: 'right', alignItems: 'center', height: '4px' }}></button>
                            </span>
                        ) : (
                            <span className="badge badge-primary text-bg-secondary-soft">{row.section}</span>
                        )}
                    </>
                ),
            });
        }

        return userColumns;
    };


    const userColumns = getUsersColumns();

    const userTagColumns = getUsersColumns('Tag');

    const reloadDataTable = (entity) => {
        const ref = dataTableRefs[entity];
        if (ref && ref.current) {
            ref.current.handleFilter(new FormData());
        }
    };

    const dataTableRefs = {
        Tags: useRef(null),
        Groups: useRef(null),
        Service: useRef(null),
        Category: useRef(null),
    };



    const editLabel = (index) => {
        setCurrentEditLabelIndex(index);

        setIsEditing(true);

        setEditingValue(lables[index]);

        setModal(true);
    }

    const setLabel = (label) => {

        setEditingValue(label);

        // lables[currentEditLabelIndex] = label;

    }

    const saveLabel = () => {

        lables[currentEditLabelIndex] = editingValue;

        const formData = new FormData();

        const labelData = JSON.stringify(lables);

        formData.append('data', labelData);


        sendRequest('groups/tags/update', 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    // props.navigate('/tags-manage/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

        setModal(false);

        setIsEditing(false);

    }

    return (
        <Layout title="Tags List" content="container-fluid">
            <div className="groups">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2" >Tags List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    {/* <li className="breadcrumb-item"><Link to="/group-manage/lists">Groups & Tags</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Tags</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            {/* <ul className="d-flex">

								<ContactNav from={2} />
								{
									hasPermission('createGroup') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/group-manage/create">
												<Icon name="plus" />
												<span>Add Group</span>
											</Link>

										</li>
									)
								}
							</ul> */}
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <h5 className="card-title d-inline mb-2" >{lables.Tag}</h5>

                            <Link onClick={() => editLabel('Tag')} className='ms-1' to="javascript:void(0);">
                                <Icon name="edit" />
                            </Link>

                            <Link className='btn btn-primary d-md-inline-flex btn-sm float-end d-inline mb-2' to="/tags-manage/create?type=Tag">
                                <Icon name="plus" />
                            </Link>

                            <DataTableComponent
                                childRef={dataTableRefs.Tags}
                                selectableRows={false}
                                columns={userTagColumns}
                                currentEntity={'Tags'}
                                tableClassName="data-table-head-light table-responsive add-scroll"
                                slug={'groups/lists?type=Tag'}
                            // deleteRecord={(id) => deleteRecord(id, 'Tag')}
                            />
                        </Card.Body>
                    </Card>
                </Block>

                <Block>
                    <Card>
                        <Card.Body>
                            <h5 className="card-title d-inline mb-2">{lables.Group}</h5>
                            <Link onClick={() => editLabel('Group')} className='ms-1' to="javascript:void(0);">
                                <Icon name="edit" />
                            </Link>

                            <Link className='btn btn-primary d-md-inline-flex btn-sm float-end d-inline mb-2' to="/tags-manage/create?type=Group">
                                <Icon name="plus" />
                            </Link>

                            <DataTableComponent
                                childRef={dataTableRefs.Groups}
                                selectableRows={false}
                                columns={userColumns}
                                currentEntity={'Tags'}
                                tableClassName="data-table-head-light table-responsive add-scroll"
                                slug={'groups/lists?type=Group'}
                            />
                        </Card.Body>
                    </Card>
                </Block>

                <Block>
                    <Card>
                        <Card.Body>
                            <h5 className="card-title d-inline mb-2">{lables.Service}</h5>
                            <Link onClick={() => editLabel('Service')} className='ms-1' to="javascript:void(0);">
                                <Icon name="edit" />
                            </Link>

                            <Link className='btn btn-primary d-md-inline-flex btn-sm float-end d-inline mb-2' to="/tags-manage/create?type=Service">
                                <Icon name="plus" />
                            </Link>
                            <DataTableComponent
                                childRef={dataTableRefs.Service}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive add-scroll"
                                currentEntity={'Tags'}
                                slug={'groups/lists?type=Service'}
                            />
                        </Card.Body>
                    </Card>
                </Block>

                <Block>
                    <Card>
                        <Card.Body>
                            <h5 className="card-title d-inline mb-2">{lables.Category}</h5>
                            <Link onClick={() => editLabel('Category')} className='ms-1' to="javascript:void(0);">
                                <Icon name="edit" />
                            </Link>

                            <Link className='btn btn-primary d-md-inline-flex btn-sm float-end d-inline mb-2' to="/tags-manage/create?type=Category">
                                <Icon name="plus" />
                            </Link>
                            <DataTableComponent
                                childRef={dataTableRefs.Category}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive add-scroll"
                                currentEntity={'Tags'}
                                slug={'groups/lists?type=Category'}
                            />
                        </Card.Body>
                    </Card>
                </Block>

                <Modal show={modal} onHide={() => setModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Label
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <input onChange={(e) => setLabel(e.target.value)} type="text" name="label" className="form-control" id="label" value={isEditing ? editingValue : ''} placeholder="Enter label" />

                        <button className="btn btn-primary mt-3 float-end btn-sm" onClick={() => saveLabel()}>Save</button>

                    </Modal.Body>
                </Modal>
            </div>
        </Layout>
    )

}

export default UserList;
