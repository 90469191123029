import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Offcanvas, Spinner, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Message, Select } from '../../components';
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import { convertPhoneFormat, currencySign } from '../../utilities';
import JobsNav from '../nav/JobsNav';
import Swal from 'sweetalert2';
import EditableInput from '../../pages/components/EditableInput'; // Import the EditableInput component
import EditFields from '../../pages/jobs/EditFields'; // Import the EditableInput component

function JobList(props) {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);

	const [offcanvasStart, setOffcanvasStart] = useState(false);
	const [meta, setMeta] = useState({});
	const [userColumns, setUserColumns] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pipelineLoading, setPipelineLoading] = useState(false);
	const [dataTableData, setDataTableData] = useState({});
	const [pipelineFilter, setPipelineFilter] = useState({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
	const [selectedIds, setSelectedIds] = useState([]);
	const [searchFilter, setSearchFilter] = useState(false);
	const [activeStatusId, setActiveStatusId] = useState(0);
	const [listsMileston, setListsMileston] = useState([]);
	const [masterPipelines, setMasterPipelines] = useState([]);
	const [currentCoach, setCurrentCoach] = useState(null);


	// const [editingCell, setEditingCell] = useState({ rowIndex: null, colKey: null });
	// const [editingValue, setEditingValue] = useState('');


	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, [selectedIds]);

	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'listPage');
		formData.set('filter_type', 'jobs');

		await sendRequest('jobs/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMasterPipelines(response.data.masterPipelines);

				setCurrentCoach(response.data.current_coach);

				setMeta(response.data);

				const customFields = response.data['customFields']['job_fields'];

				const transformedArray = customFields?.sort((field) => field.sorting_order).map((item) => {
					return {
						name: item.label,
						key: item.key,
						minWidth: item.type === 'select' ? '180px' : '',
						type: 'custom',
						selector: (row) => row['custom_fields'] ? row['custom_fields'][item.key] : '',
						cell: (row) => (
							<EditFields
								name={item.key}
								value={row['custom_fields'] ? JSON.parse(row['custom_fields'])[item.key] : ''}
								type={item.type}
								options={item.options?.map(concert => ({
									value: concert.option,
									label: concert.option
								}))}
								currentItem={row.id}
								fieldType={'custom'}
							>
							</EditFields>
							// <span>{JSON.parse(row['custom_fields'])[item.key]}</span>
							// <span>{item.options}</span>
						),
					};
				});

				const primaryArray = getUsersPrimaryColumns()

				if (transformedArray) {
					primaryArray.push(...transformedArray);
				}

				setUserColumns(primaryArray);
			}
			setLoading(true);
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		setCurrentCoach(null);

		const formData = new FormData(event.currentTarget);

		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		setCurrentCoach(null);

		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setLoading(false);
			setCustomDateFilter(false);
			setPipelineFilter({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
			if (searchFilter) {
				setColumnVisibility({
					id: true,
					record_owner: true,
					category_title: true,
					service_title: true,
					tag_title: true,
					pipeline: true,
					milestone: true,
					checkboxes: true,
				});

				setSearchFilter(false);
			}
			setTimeout(() => {
				setLoading(true);

			}, 100);
		}
	};

	const getSubPipeline = async (pipeline_id) => {
		setPipelineLoading(false);
		const formData = new FormData();
		await sendRequest(`pipelines/${pipeline_id}`, 'POST', formData).then(response => {
			if (response.status === true) {
				setListsMileston(response.data.lists);
				setActiveStatusId(response.data.active_status_id);
				setPipelineLoading(true);
			}

		})
			.catch(error => {
				setPipelineLoading(true);
				console.error('POST request error:', error);
			});
	}
	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to move selected jobs to the recycle bin?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`jobs/update/bulk/delete`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};


	const getUsersPrimaryColumns = () => {
		const userColumns = [
			{
				name: <Form.Check value="selectAll" onChange={(e) => handleCheckbox(e)} type="checkbox" id="selectAllCheckbox" label="" />,
				key: 'checkboxes',
				width: '60px',
				selector: (row) => row.id,
				cell: (row) => (
					<Form.Check checked={selectedIds.includes(row.public_id)} id={`flexCheckDefault${row.id}`} defaultValue={row.public_id} onChange={(e) => handleCheckbox(e)} type="checkbox" label="" />
				),
			},
			{
				name: "View",
				key: 'id',
				width: '74px',
				selector: (row) => row.id,
				cell: (row) => (
					<Link to={`/jobs/detail/${row.public_id}`} style={{ fontSize: '20px' }}>
						<Icon name="eye" ></Icon>
					</Link>

					// <span>{row.id}</span>
				),
			},
			{
				name: "Related To",
				key: 'related_to',
				width: '174px',
				selector: (row) => row.public_id,
				cell: (row) => (
					<div className='d-flex flex-column'>
						{row.related_ids_array_details.map((item, index) => (
							<Link to={`/crm-manage/leads/dialer/${item.public_id}`}>
								<span className='w-100'>
									{item.name}
								</span>
							</Link>
						))}
					</div>
					// <Link to={`/crm-manage/leads/dialer/${row.related_to_public_id}`}>
					// 	{row.related_to_name ?? row.id}
					// </Link>
				),
			},

			{
				name: "Owner",
				key: 'record_owner',
				width: '180px',
				selector: (row) => row.record_owner,
				cell: (row) => (
					<EditFields
						name={'record_owner'}
						value={row.coach_partner_id}
						type={"select"}
						options={meta?.coachPartners?.map(concert => ({
							value: concert.id,
							label: concert.first_name + ' ' + concert.last_name
						}))}
						currentItem={row.id}
					>
					</EditFields>
				),
			},
			// {
			// 	name: "Related To",
			// 	key: 'related_to_name',
			// 	minWidth: '140px',
			// 	selector: (row) => row.related_to_name,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='related_to_name'
			// 			value={row.related_to_name}
			// 			type={"text"}
			// 			currentItem={row.id}
			// 			leadArrays={row.related_ids_array_details}
			// 		>
			// 		</EditFields>
			// 		// <span>{row.related_to_name}</span>
			// 	),
			// },
			{
				name: meta.tags_labels?.Category ?? 'Category',
				key: 'category_title',
				minWidth: '180px',
				selector: (row) => row.category_title,
				cell: (row) => (
					<EditFields
						name={'category_title'}
						value={row.category_id}
						type={"multi-select"}
						options={meta?.categories?.map(concert => ({
							value: concert.id,
							label: concert.title
						}))}
						currentItem={row.id}
					>
					</EditFields>
					// <span>{row.category_title}</span>
				),
			},
			{
				name: meta.tags_labels?.Service ?? 'Service',
				key: 'service_title',
				minWidth: '180px',
				selector: (row) => row.service_title,
				cell: (row) => (
					<EditFields
						name={'service_title'}
						value={row.service_id}
						type={"multi-select"}
						options={meta?.services?.map(concert => ({
							value: concert.id,
							label: concert.title
						}))}
						currentItem={row.id}
					>
					</EditFields>
					// <span>{row.service_title}</span>
				),
			},
			{
				name: meta.tags_labels?.Tag ?? 'Tag',
				key: 'tag_title',
				minWidth: '180px',
				selector: (row) => row.tag_title,
				cell: (row) => (
					<EditFields
						name={'tag_title'}
						value={row.tag_id}
						type={"multi-select"}
						options={meta?.tags?.map(concert => ({
							value: concert.id,
							label: concert.title
						}))}
						currentItem={row.id}
					>
					</EditFields>
					// <span>{row.tag_title}</span>
				),
			},
			{
				name: "Pipeline",
				key: 'pipeline',
				minWidth: '140px',
				selector: (row) => row.pipeline?.master_title,
				cell: (row) => (
					<EditFields
						name='pipeline'
						value={row.pipeline_id}
						type={"select"}
						options={row.pipeLines}
						activeMasterId={row.pipeline_id}
						activeStatusId={row.status_id}
						currentItem={row.id}
						masterPipelines={row.masterPipelines}

					>
					</EditFields>
					// <Link to={`/pipelines-manage/view/${row.pipeline_public_id}`}>
					// 	{row.pipeline?.master_title}
					// </Link>
				),
			},
			{
				name: "Milestone",
				key: 'milestone',
				minWidth: '140px',
				selector: (row) => row.pipeline?.status_title,
				cell: (row) => (
					<EditFields
						name='milestone'
						value={row.pipeline?.id}
						type={"select"}
						options={row.pipeLines}
						activeMasterId={row.pipeline_id}
						activeStatusId={row.status_id}
						currentItem={row.id}
						masterPipelines={row.masterPipelines}

					>
					</EditFields>
					// <span>{row.pipeline?.status_title}</span>
				),
			},

			{
				name: "Amount",
				key: 'amount_formated',
				selector: (row) => row.amount_formated,
				cell: (row) => (
					<EditFields
						name='amount_formated'
						value={row.amount_formated}
						type={"number"}
						currentItem={row.id}
					>
					</EditFields>
					// <span
					// 	onDoubleClick={() => handleCellDoubleClick(row.id, 'amount_formated', row.amount_formated)}
					// >
					// 	{editingCell.rowIndex === row.id && editingCell.colKey === 'amount_formated' ? (
					// 		<EditableInput
					// 			value={editingValue}
					// 			type={"number"}
					// 			onChange={handleInputChange}
					// 			onKeyPress={handleEnterKeyPress}
					// 			onBlur={handleInputBlur}
					// 		>
					// 		</EditableInput>
					// 	) : (
					// 		<span className="price-end">{currencySign}{row.amount_formated}</span>
					// 	)}
					// </span>
				),
			},

			{
				name: "Hours Open",
				key: 'open_days_hours',
				selector: (row) => row.open_days_hours,
				cell: (row) => (
					<EditFields
						name='open_days_hours'
						value={row.open_days_hours}
						type={"number"}
						currentItem={row.id}
					>
					</EditFields>
				),
			},

			// {
			// 	name: "Estimated Hours",
			// 	key: 'estimated_hours',
			// 	minWidth: '150px',
			// 	selector: (row) => row.estimated_hours,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='estimated_hours'
			// 			value={row.estimated_hours}
			// 			type={"number"}
			// 			currentItem={row.id}
			// 		>
			// 		</EditFields>
			// 	),
			// },

			{
				name: "Hours",
				key: 'actual_hours',
				minWidth: '128px',
				selector: (row) => row.actual_hours,
				cell: (row) => (
					<EditFields
						name='actual_hours'
						value={row.actual_hours}
						type={"number"}
						currentItem={row.id}
					>
					</EditFields>
				),
			},
			// {
			// 	name: "Job Site",
			// 	key: 'job_site',
			// 	selector: (row) => row.job_site,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='job_site'
			// 			value={row.job_site}
			// 			type={"text"}
			// 			currentItem={row.id}
			// 		>
			// 		</EditFields>
			// 	),
			// },
			// {
			// 	name: "Lookup Time",
			// 	key: 'lookup_time',
			// 	selector: (row) => row.lookup_time,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='lookup_time'
			// 			value={row.lookup_time}
			// 			type={"text"}
			// 			currentItem={row.id}
			// 		>
			// 		</EditFields>
			// 	),
			// },
			// {
			// 	name: "Site Address",
			// 	key: 'site_address',
			// 	selector: (row) => row.site_address,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='site_address'
			// 			value={row.site_address}
			// 			type={"text"}
			// 			currentItem={row.id}
			// 		>
			// 		</EditFields>
			// 	),
			// },
			// {
			// 	name: "Billing Address",
			// 	key: 'billing_address',
			// 	selector: (row) => row.billing_address,
			// 	cell: (row) => (
			// 		<EditFields
			// 			name='billing_address'
			// 			value={row.billing_address}
			// 			type={"text"}
			// 			currentItem={row.id}
			// 		>
			// 		</EditFields>
			// 	),
			// },
			{
				name: "Dated",
				key: 'dated',
				selector: (row) => row.dated,
				cell: (row) => (
					<span>{row.dated}</span>
				),
			},
			// {
			// 	name: "Phone",
			// 	key: 'phone',
			// 	selector: (row) => row.phone_formated,
			// 	cell: (row) => (
			// 		<span>{row.phone_formated}</span>
			// 	),
			// },


		];

		return userColumns;
	}


	const getInitialColumnVisibility = () => {
		const storedVisibility = localStorage.getItem('jobsColumnVisibility');
		if (storedVisibility) {
			let visible = JSON.parse(storedVisibility);
			visible.checkboxes = true;
			return visible;
		}

		return {
			checkboxes: true,
			id: true,
			record_owner: true,
			category_title: true,
			service_title: true,
			tag_title: true,
			pipeline: true,
			milestone: true,
		};
	};

	const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);
	useEffect(() => {
		localStorage.setItem('jobsColumnVisibility', JSON.stringify(columnVisibility));
	}, [columnVisibility]);

	const toggleColumnVisibility = (key) => {
		setColumnVisibility((prevVisibility) => ({
			...prevVisibility,
			[key]: !prevVisibility[key],
		}));

		const formData = new FormData();
		formData.set('columnVisibility', JSON.stringify(columnVisibility));

		sendRequest('visible-columns/jobs/set', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	};
	const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);

	const handleCheckbox = (e) => {
		const { value, checked } = e.target;
		if (value === 'selectAll') {
			if (checked) {
				setSelectedIds(dataTableData.records ? dataTableData.records.map((row) => row.public_id) : []);
			} else {
				setSelectedIds([]);
			}
		} else {
			setSelectedIds((prevSelectedIds) => {
				if (checked) {
					return [...prevSelectedIds, value];
				} else {
					return prevSelectedIds.filter((id) => id !== value);
				}
			});
		}

	};

	const handlePipelines = (data) => {
		setDataTableData(data);
		setSelectedIds([]);
		if (data.selected_columns != '') {
			setColumnVisibility(data.selected_columns);
		}
	};

	const exportRecords = async () => {
		if (selectedIds.length == 0) {
			Swal.fire({
				text: 'Kindly choose a contact record from the provided list to proceed with the desired action.',
				icon: 'warning',
			});
			return false;
		}
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to submit export request for selected contacts?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`jobs/update/bulk/export`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							props.navigate(`/jobs/export`)
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};



	return (
		<Layout title="Jobs List" content="container-fluid">
			<div className="Jobs">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Job List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Jobs</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								<JobsNav from={1} />
								{
									hasPermission('createJobs') && (

										<Link className={`btn btn-sm  mr-5 btn-primary`} to={'/jobs/create'}>
											<Icon name="plus"></Icon> <span>Add Job</span>
										</Link>
									)
								}

								<Button size='sm' className='mr-5' variant="primary" onClick={() => setOffcanvasStart(true)}>
									<Icon name={'setting-alt'} />
								</Button>


								<Offcanvas className="w-50 h-50" placement="end" show={offcanvasStart} onHide={() => setOffcanvasStart(false)}>
									<Offcanvas.Header closeButton />
									<Offcanvas.Body>
										<b>
											Primary Fields
										</b>
										<div class="filter-list-container">
											{userColumns.filter(column => column.type !== 'custom').map((column, i) => (
												<div key={column.key} class={`filter-slot ${i == 0 && ('d-none')}`}>
													<div className='mb-2 radio-label-custom'>

														<Form.Check
															type="checkbox"
															inline
															label={column.name}
															id={`${column.key}`}
															key={column.key}
															checked={columnVisibility[column.key]}
															onChange={() => toggleColumnVisibility(column.key)}
														/>
													</div>
												</div>

											))}
										</div>

										<b>
											Custom Fields
										</b>
										<div class="filter-list-container">

											{userColumns.filter(column => column.type === 'custom').map((column, i) => (
												<div key={column.key} class={`filter-slot`}>
													<div className='mb-2 radio-label-custom'>

														<Form.Check
															type="checkbox"
															inline
															label={column.name}
															id={`${column.key}`}
															key={column.key}
															checked={columnVisibility[column.key]}
															onChange={() => toggleColumnVisibility(column.key)}
														/>
													</div>
												</div>

											))}
										</div>
									</Offcanvas.Body>

								</Offcanvas>

								<Dropdown>
									<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-primary btn-sm me-n1 hide-caret">
										<Icon name={'more-h'} />
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="center">
										<div className="dropdown-content py-1">
											<LinkList className="link-list-hover-bg-primary link-list-md">

												{
													hasPermission('viewLeadsFilter') && (
														<LinkListItem to={`/crm-manage/leads/filters/lists/jobs`}>
															<span>List & Filters</span>
														</LinkListItem>
													)
												}
												{
													hasPermission('deleteLeads') && (
														<button
															title='You must select at least one record'
															disabled={selectedIds < 1}
															className="btn p-0 border-0" onClick={() => deleteRecord('MA==')}>
															Mass Delete
														</button>
													)
												}

												{
													hasPermission('createLeads') && (
														<LinkListItem to={`/jobs/import`}>
															<span>Import Jobs</span>
														</LinkListItem>
													)
												}
												{
													hasPermission('viewLeads') && (
														<button
															className="btn p-0" onClick={() => exportRecords()}>
															Export Jobs
														</button>
													)
												}

											</LinkList>
										</div>
									</Dropdown.Menu>
								</Dropdown>

							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card>
								<Card.Body>

									<Form ref={formRef} onSubmit={handleSubmit}>
										<Row className='align-items-center'>
											<Col>
												<div>
													<Select name="coach_partner_id" defaultValue={currentCoach}>
														<option value="">Owner </option>
														{
															meta.coachPartners && meta.coachPartners.map((row, i) => {
																return <option key={i} value={row.id} >{row.first_name} {row.last_name}</option>
															})

														}
													</Select>
												</div>
											</Col>

											<Col>
												<Select name="search_filter_id"
													onChange={() => setSearchFilter(true)}>
													<option value="">All</option>

													<optgroup label="Select Filter">

														{
															meta.search_filters && meta.search_filters.map((row, i) => {
																return <option key={i} value={row.id}>{row.name}</option>
															})

														}
													</optgroup >
												</Select>
											</Col>
											<Col>
												<Select onChange={(e) =>
														e == "Custom Date"
															? setCustomDateFilter(true)
															: setCustomDateFilter(false)
													}
													name="date_range">
													<option value="">Date</option>
													<option value="Current Week">Current Week</option>
													<option value="Last Week">Last Week</option>
													<option value="Current Month">Current Month</option>
													<option value="Last Month">Last Month</option>
													<option value="Last 3 Months">Last 3 Months</option>
													<option value="Last 6 Months">Last 6 Months</option>
													<option value="Current Year">Current Year</option>
													<option value="Last Year">Last Year</option>
													<option value="Custom Date">Custom Date</option>
												</Select>
											</Col>
											{
												customDateFilter && (
													<>
														<Col>
															<Form.Control type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>

														<Col>
															<Form.Control type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>
													</>
												)
											}

											<Col>
												<div>
													<Select name="category_id">
														<option value="">Category </option>
														{
															meta.categories && meta.categories.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})

														}
													</Select>
												</div>
											</Col>
											<Col>
												<div>
													<Select name="service_id">
														<option value="">Service </option>
														{
															meta.services && meta.services.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})

														}
													</Select>
												</div>
											</Col>

											<Col>
												<div>
													<Select name="tag_id">
														<option value="">Tag </option>
														{
															meta.tags && meta.tags.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})

														}
													</Select>
												</div>
											</Col>

											<Col>
												<div>
													<Select className="mb-0" name="master_pipeline_id"
														onChange={(e) => {
															getSubPipeline(e[0]);
														}}
													>
														<option value="">Pipeline </option>
														{
															meta.masterPipelines && meta.masterPipelines.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})

														}
													</Select>

													<Form.Control type="hidden" name="pipeline_id" value={pipelineFilter.pipelineId} />
													<Form.Control type="hidden" name="status_id" value={pipelineFilter.statusId} />

												</div>
											</Col>
											{pipelineLoading && (
												<Col>
													<div>
														<Form.Select defaultValue={activeStatusId} placeholder="Type" name="status_id">
															<option value="">Milestone </option>
															{
																listsMileston.map((row, i) => {
																	return (
																		<>
																			<optgroup label={row.title}>
																				{
																					row?.pipeline_status?.length > 0 && row?.pipeline_status?.map((status, k) => {
																						return (
																							<option key={k} value={status.id}>{status.title}</option>
																						)
																					})
																				}
																			</optgroup>
																		</>
																	)
																})
															}
														</Form.Select>
													</div>
												</Col>
											)}

											<Col>
												<Button id="submitButton" size="sm" type="submit" className='mr-5'>Filter</Button>
												<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

											</Col>
										</Row>
									</Form>

									<div className='table-custom-scroll'>
										<DataTableComponent
											childRef={childRef}
											selectableRows={false}
											columns={visibleColumns}
											tableClassName="data-table-head-light table-responsive LeadListTable table-edits"
											slug={'jobs/lists?status=1'}
											sendDataToParent={handlePipelines}
										/>
									</div>

								</Card.Body>
							</Card>
						)
					}
				</Block>
			</div>
		</Layout>
	)
}

export default JobList;
