import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select, Tinymce } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import CalanderNav from '../../nav/CalanderNav';
import Swal from 'sweetalert2';
import { isObjectNotEmpty } from '../../../utilities';
function CalendarAutoResponderSeries() {
	const { id } = useParams();
	const { hasPermission } = useAuth();
	const [meta, setMeta] = useState({ coachPartners: [] });
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});
	const [campaignData, setCampaignData] = useState({});
	const formRef = useRef(null);
	const childRef = useRef(null);
	const [token, setToken] = useState('');
	const [type, setType] = useState('sms');
	const textareaRef = useRef(null);
	const [loadingTemplate, setLoadingTemplate] = useState(false);


	useEffect(() => {
		const fetchData = async () => {
			await getTemplateMeta();
			fetchRecordById();
		};
		fetchData();
	}, []);

	const fetchRecordById = () => {
		sendRequest(`campaign/${id}/show`, 'POST').then(response => {
			if (response.status === true) {
				setCampaignData(response.data.list);
			}
			setLoading(true);
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}




	const getTemplateMeta = async () => {
		setLoading(false);
		const formData = new FormData();
		formData.set('master_type', 'global');
		formData.set('from', 'calander');

		await sendRequest(`crm/templates/meta`, 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const openModal = (data = {}) => {
		if (isObjectNotEmpty(data)) {
			setData(data);
			setTimeout(() => {
				setModal(true);

			}, 100);
		} else {
			setModal(true);
		}
	}

	const closeModal = (fetchRecord = false) => {
		if (fetchRecord) {
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
		}
		setType('sms');

		setData({});
		setModal(false);

	}

	const userColumns = [
		{
			name: "Remind Before",
			key: 'interval_of_day',
			selector: (row) => row.title,
			cell: (row) => (
				<span className='text-capitalize'>{row.interval_of_day} {row.send_type}</span>
			),
		},

		{
			name: "Type",
			key: 'type',
			selector: (row) => row.type,
			cell: (row) => (
				<span className='text-capitalize'>{row.type}</span>
			),
		},

		{
			name: "Created By",
			key: 'created_by_user',
			selector: (row) => row.created_by_user,
			cell: (row) => (
				<span>{row.created_by_user}</span>
			),
		},

		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},

		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">

							{
								hasPermission('deleteCalendarsAutoResponder') && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}
						</LinkList>
					</div>
				</div>
			),
		},

	];

	const handleSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		submitData.append('campaign_id', id);

		let url = `calendar/responder/series/create`;
		if (data.public_id) {
			url = `calendar/responder/series/update/${data.public_id}`
		}

		sendRequest(url, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					closeModal(true);
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to remove this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`calendar/responder/series/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const insertValueAtCursor = (valueToInsert) => {
		const textarea = textareaRef.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const newText = data.body.substring(0, start) + valueToInsert + data.body.substring(end);
		setData((prevState) => ({
			...prevState,
			body: newText,
		}));

		// Set the cursor position after the inserted value
		textarea.selectionStart = start + valueToInsert.length;
		textarea.selectionEnd = start + valueToInsert.length;
		textarea.focus();
	};

	return (
		<Layout title="Sequence" content="container-fluid">
			<div className="Autoresponder_Sequence">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">{campaignData.title}</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Sequence</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								<ul className="d-flex">


									{
										hasPermission('createCalendarsAutoResponder') && (
											<li>
												<button className="btn btn-primary d-md-inline-flex btn-sm" onClick={() => openModal()}>
													<Icon name="plus" />
													<span>Add Sequence</span>
												</button>

											</li>
										)
									}

									<li>
										<Link className='btn btn-secondary d-md-inline-flex btn-sm ms-2' to="/global-template">
											<span>Autoresponder Template</span>
										</Link>

									</li>
								</ul>

							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'calendar/responder/series/lists'}
								extraCondition={{ 'responder_id': id }}
								serchBox={false}
							/>
						</Card.Body>
					</Card>
				</Block>

				<Modal size='xl' show={modal} onHide={() => closeModal()}>
					<Form onSubmit={handleSubmit}>
						<Modal.Header closeButton>
							<Modal.Title className="calander-location" as="h4">{data.public_id ? 'Update' : 'Add'} Sequence</Modal.Title>
						</Modal.Header>


						<Modal.Body >
							<div className='form-group'>
								<label>Remind Record User</label>
								<div class="input-group input-group-sm mb-2">
									<span class="input-group-text w-25">Before</span>
									<input name='interval' className='form-control w-50' required type='number' min={1} defaultValue={1} />
									{/* <select name='interval' class="form-control w-50">
										<option value={'5'}>5</option>
										<option value={'10'}>10</option>
										<option value={'15'}>15</option>
										<option value={'20'}>20</option>
										<option value={'25'}>25</option>
										<option value={'30'}>30</option>
										<option value={'35'}>35</option>
										<option value={'40'}>40</option>
										<option value={'50'}>45</option>
										<option value={'50'}>45</option>
										<option value={'55'}>55</option>
										<option value={'60'}>60</option>
									</select> */}

									<span class="input-group-text-custom  w-25">
										<select name='interval_type' class="form-control custom-boder w-100">
											<option value={'minutes'}>Minutes</option>
											<option value={'hours'}>Hours</option>
											<option value={'day'}>Days</option>
										</select>
									</span>
								</div>
							</div>

							<div className='form-group'>
								<label>Type</label>
								<div className='inline-group mb-2'>
									<Form.Check inline
										label="Sms"
										type="radio"
										id="inlineradio1"
										value={'sms'}
										defaultChecked={type == 'sms' ? true : false}
										onChange={(e) => {
											setLoadingTemplate(true);
											setType('sms');
											setData({});
											setTimeout(() => {
												setLoadingTemplate(false);
											}, 100);
										}
										}
										name="type"
									/>

									<Form.Check inline
										label="Email"
										type="radio"
										value={'email'}
										id="inlineradio1"
										defaultChecked={data.notify_type == 'email' ? true : false}
										onChange={(e) => {
											setLoadingTemplate(true);
											setType('email');
											setData({});
											setTimeout(() => {
												setLoadingTemplate(false);
											}, 100);
										}
										}
										name="type"
									/>
								</div>
							</div>




							{
								!loadingTemplate && (
									<div className='template-loader' >
										{type == 'email' ?
											<div className='email-responder'>

												<Select name="template_id" onChange={(e) => {
													const desiredObject = meta?.emailTemplate.find(item => item.id == e[0]);
													const body = desiredObject?.body || "";
													const subject = desiredObject?.title || "";
													setData((prevState) => ({
														...prevState,
														body: body,
														subject: subject,

													}));
												}}>
													<option value="">Select Template</option>
													{
														meta?.emailTemplate && meta.emailTemplate.map((row, i) => {
															return <option key={i} value={row.id}>{row.title}</option>
														})
													}
												</Select>

												<div className="token-button d-none">

													<Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
														<option value=''>Select Token</option>
														<optgroup label="Extras Fields">
															{
																meta?.tokens?.extras && meta.tokens.extras.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>

														<optgroup label="Default Fields">
															{
																meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>
														<optgroup label="Custom Fields">
															{
																meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>
													</Select>


													<Button type="button" onClick={() => setData((prevState) => ({
														...prevState,
														subject: data.subject + ` ${token}`
													}))}
														variant="outline-secondary">
														Insert Subject
													</Button>

												</div>

												<Form.Group className="form-group mt-4">
													<div className="form-control-wrap">
														<Form.Control
															value={data.subject}
															onChange={handleChange}
															name="subject"
															placeholder="Enter subject"
															required />
													</div>
												</Form.Group>

												<Form.Group className="form-group mt-4">
													<Tinymce
														leadToken={true}
														extraToken={meta?.tokens?.extras}
														userToken={false} height={300}
														initialValue={data.body} textareaName={'body'} toolbar={true}
													/>
												</Form.Group>
											</div>
											:
											<div className='sms-responder'>

												<Select className="mb-2" name="froms">
													<option value=''>Select Phone</option>
													{
														meta?.phone_numbers && meta.phone_numbers.map((row, i) => {
															return <option key={i} value={`${row.number}`}>{row.number}</option>;
														})
													}
												</Select>

												<Select name="template_id" onChange={(e) => {
													const desiredObject = meta?.smsTemplate.find(item => item.id == e[0]);
													const body = desiredObject?.body || "";
													setData((prevState) => ({
														...prevState,
														body: body,
													}));
												}}>
													<option value="">Select Template</option>
													{
														meta?.smsTemplate && meta.smsTemplate.map((row, i) => {
															return <option key={i} value={row.id}>{row.title}</option>
														})
													}
												</Select>

												<div className="token-button">

													<Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
														<option value=''>Select Token</option>
														<optgroup label="Extras Fields">
															{
																meta?.tokens?.extras && meta.tokens.extras.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>

														<optgroup label="Default Fields">
															{
																meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>

														<optgroup label="Custom Fields">
															{
																meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
																	return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
																})
															}
														</optgroup>
													</Select>
													<Button size='sm' type="button" onClick={() => insertValueAtCursor(` ${token}`)} variant="outline-secondary">
														Insert Token
													</Button>

												</div>

												<Form.Group className="form-group mt-4">
													<div className="form-control-wrap">
														<Form.Control
															ref={textareaRef}
															value={data.body}
															onChange={handleChange}
															as="textarea"
															name="body"
															placeholder="Enter body"
															required />
													</div>
												</Form.Group>
											</div>

										}
									</div>
								)
							}




						</Modal.Body>
						<Modal.Footer>
							<Button size='sm' variant="danger" onClick={() => closeModal()}>Close</Button>
							<Button size='sm' variant="primary" type="submit" >{data.public_id ? 'Update' : 'Add'}</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</div>
		</Layout>
	)
}

export default CalendarAutoResponderSeries;
