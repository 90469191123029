import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { capitalizeFirstLetter, toInitials, uInfo } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";

function CreatePagesCms(props) {
    const { type, id } = useParams();

    const [meta, setMeta] = useState({ coachPartners: [] });


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (id) {
            submitData.append('proposal_id', id);

        }

        sendRequest(`cms/${type}/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(-1);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="CMS" content="container-fluid">
            <div className="group-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">{capitalizeFirstLetter(type)}</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={id ? `/cms-manage/${type}/lists/${id}` : `/cms-manage/${type}/lists`}>{capitalizeFirstLetter(type)}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        {
                                            (uInfo().type === 'Manager' || uInfo().type === 'SubManager') ? (
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Type</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select type="text" name="subject" placeholder="subject" required >
                                                                <option value={"Phone"}>Phone</option>
                                                                <option value={"Email"}>Email</option>
                                                                <option value={"Phone/Email"}>Phone/Email</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            )
                                                : (


                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="title">Subject</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="subject" placeholder="Subject" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                )
                                        }

                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                {
                                                    (uInfo().type === 'Manager' || uInfo().type === 'SubManager') ?
                                                        <Form.Control as={'textarea'} name="body" placeholder="Enter disclaimer" required />

                                                        :
                                                        <Tinymce textareaName={'body'} toolbar={true} />
                                                }
                                            </Form.Group>
                                        </Col>

                                        {
                                            !['disclaimer', 'termsofservice', 'privacypolicy'].includes(type) ? (
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Available For</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select type="text" name="available_for" placeholder="available_for" required >
                                                                <option value={"All"}>All</option>
                                                                {/* <option value={"Reseller"}>Reseller</option> */}
                                                                <option value={"Manager"}>Client</option>
                                                                <option value={"Staff"}>Staff</option>
                                                                <option value={"Member"}>Member/Contact</option>

                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            ) : (
                                                <Form.Control type="hidden" name="available_for" value={"All"} required />
                                            )
                                        }







                                        <Col className="col-12">
                                            <Button type="submit" className="btn-sm">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default CreatePagesCms;