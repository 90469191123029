import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import { Interval } from "../../../store/customData";

function CreditCreate(props) {
    const [meta, setMeta] = useState({});
    const [data, setData] = useState({ type: 'Free'});
    const [descriptions, setDescriptions] = useState([]);
    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('packages/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('meta', JSON.stringify(descriptions));
        submitData.append('package_for', 'AccountCredit');
        submitData.append('role_id', 0);


        sendRequest('packages/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/package-manage/credit/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value, placeholder } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if(name == 'type' && value == 'Free'){
            setData((prevState) => ({
                ...prevState,
               package_interval: 'OneTime',
            }));
        }

        const targetIndex = descriptions.findIndex((element) => element.key === name);

        const filledDescription = ['credits', 'managers_limit', 'leads_limit', 'members_limit']
        if (filledDescription.includes(name)) {
            if (targetIndex !== -1) {
                const newDescriptions = [...descriptions];
                newDescriptions[targetIndex].description_meta = value + " - " + placeholder;
                console.log(newDescriptions)
                setDescriptions(newDescriptions);
            } else {
                setDescriptions([...descriptions, { key: name, description_meta: `${value} - ${placeholder}`, readOnly: 1 }]);
            }

        }
    }

    const handleAddClick = () => {
        setDescriptions([...descriptions, { key: Math.random(), description_meta: "" }]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const description = [...descriptions];
        description[index][name] = value;
        setDescriptions(description);
    };

    const handleRemoveClick = (indexToRemove) => {
        const newList = descriptions.filter((item, index) => index !== indexToRemove);
        setDescriptions(newList);

    };

    return (
        <Layout title="Credit Package" content="container-fluid">
            <div className="group-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Credit Package</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/package-manage/credit/lists">Credit Package</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label>Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.title} onChange={(e) => handleChange(e)} type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label>Sub-Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.sub_title} onChange={(e) => handleChange(e)} type="text" name="sub_title" placeholder="Sub title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label>Type</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Select defaultValue={data.type} onChange={(e) => handleChange(e)} type="text" name="type" placeholder="Type" required >
                                                        <option value={'Free'}>Free</option>
                                                        <option value={'Paid'}>Paid</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label>Package Interval</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Select disabled={data.type == 'Free' ? true : false} value={data.package_interval} defaultValue={data.package_interval} onChange={(e) => handleChange(e)} type="text" name="package_interval" placeholder="Package Interval" required >
                                                        {
                                                            Interval.map((row, i) => {
                                                                return <option value={row.id} key={i}>{row.label}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </Col>



                                        {
                                            data.type == 'Paid' && (
                                                <>
                                                    <Col md="4">
                                                        <Form.Label>Price</Form.Label>

                                                        <InputGroup size="sm">
                                                            <InputGroup.Text> <Icon name={'sign-dollar'} ></Icon> </InputGroup.Text>
                                                            <Form.Control min={1} defaultValue={data.price} onChange={(e) => handleChange(e)} type="number" name="price" placeholder="Price" required />
                                                        </InputGroup>
                                                    </Col>
                                                </>
                                            )
                                        }

                                        <Col md="4">
                                            <Form.Label>Credits </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>  &cent;</InputGroup.Text>
                                                <Form.Control defaultValue={data.credits} onChange={(e) => handleChange(e)} type="number" name="credits" placeholder="Credits" required />
                                            </InputGroup>
                                        </Col>

                                        <div className="mb-3">
                                            <label> Description </label><br></br>
                                            <Button
                                                type="button"
                                                className="btn btn-sm info ml-5"
                                                onClick={() => handleAddClick()}
                                            >
                                                Add Description
                                            </Button>
                                        </div>
                                        {descriptions.map((x, i) => {
                                            return (
                                                <Col md="4" key={x.key}>
                                                    <InputGroup>
                                                        <Form.Control readOnly={x.readOnly} onChange={(e) => handleInputChange(e, i)} value={x.description_meta} type="text" name="description_meta" required />
                                                        <InputGroup.Text className="p-0">
                                                            <Button disabled={x.readOnly} onClick={() => handleRemoveClick(i)} className="btn-soft" variant="danger" size="sm">
                                                                <Icon name={'trash'} ></Icon>
                                                            </Button>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            );
                                        })}




                                        <Col className="col-12">
                                            <Button type="submit" className="btn-sm">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout >
    )
}

export default CreditCreate;