import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, InputGroup, ListGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, MediaGroup, MediaText } from '../../components';
import { sendRequest } from "../../utilities/api";

import { uInfo } from "../../utilities";
import { countries } from "../../store/customData";
import Swal from 'sweetalert2';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function LeadCreate(props) {
    let query = useQuery();


    const [meta, setMeta] = useState({ coachPartners: [], tags: [], categories: [], services: [], masterPipelines: [] });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ contact_type: uInfo().id ? 'existing' : 'new', related_id: 0, lead_name: '' });
    const [oldValue, setOldValue] = useState({});
    const [customField, setCustomField] = useState([]);
    const [customFieldData, setCustomFieldData] = useState({});
    const [formloading, setFormLoading] = useState(false);

    const [masterPipeline, setPipelineMaster] = useState([]);
    const [activeMasterId, setActiveMasterId] = useState('MA==');
    const [activeStatusId, setActiveStatusId] = useState(0);
    const [listsMileston, setListsMileston] = useState([]);
    const [existingLead, setExistingLead] = useState([]);
    const [leadArrays, setLeadArrays] = useState([]);
    const [get_related_id, setGetRelatedId] = useState(query.get('rel_id'));
    const [defultCategory, setDefultCategory] = useState(null);
    const [defultService, setDefultService] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
        };
        fetchData();
    }, []);

    const addNewALeadrray = (related_id, name) => {
        const isRelatedIdExist = leadArrays.some(array => array.related_id === related_id);
        if (!isRelatedIdExist) {
            const newArray = [...leadArrays, { related_id: related_id, name: name }];
            setLeadArrays(newArray);
        }
        setData({ ...data, related_id: 0, lead_name: `` });
    };

    const unsetLeadArray = (related_id) => {
        const filteredArrays = leadArrays.filter(array => array.related_id !== related_id);
        setLeadArrays(filteredArrays);
    };


    const fetchMeta = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        formData.set('get_related_id', get_related_id);
        await sendRequest('jobs/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);

                setCustomField(response.data.customFields.job_fields);

                const defaultCat = response.data.categories.filter(category => category.is_default == 1)
                setDefultCategory(defaultCat.length ? defaultCat[0].id : []);

                const defaultService = response.data.services.filter(service => service.is_default == 1)
                setDefultService(defaultService.length ? defaultService[0].id : []);

                if (get_related_id) {
                    setLeadArrays(response.data.lead_array);
                }
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const handleChange = (e, key) => {

    }

    const getSubPipeline = async (pipeline_id) => {
        if (pipeline_id == '') {
            setListsMileston([]);
            setActiveMasterId('MA==');
            setActiveStatusId(0);
        }
        const formData = new FormData();
        await sendRequest(`pipelines/${pipeline_id}`, 'POST', formData).then(response => {
            if (response.status === true) {
                setListsMileston(response.data.lists);
                setActiveMasterId(pipeline_id);
                setActiveStatusId(response.data.active_status_id);
            } else {
                setListsMileston([]);
                setActiveMasterId('MA==');
                setActiveStatusId(0);
            }


        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }
    const createUi = () => {
        return customField && customField.map((elementSchema, elementIndex) => {

            const elementName = elementSchema.key;



            if (customField.hasOwnProperty(elementName) && !oldValue[elementName]) {
                setCustomFieldData((prevData) => ({
                    ...prevData,
                    [elementName]: oldValue[elementName],
                }));
            }

            if (elementSchema.type === 'select') {
                return (
                    <div className="col-md-4 mb-3" key={elementSchema.label}>
                        <label class="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                        <Form.Select defaultChecked={customField[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key}
                            id={elementSchema.key}>

                            <option
                                key={'-1'}
                                value={``}
                            >
                                Select Option
                            </option>
                            {elementSchema.options.map((row, i) => {
                                return (
                                    <option
                                        key={i}
                                        value={`${row.option}`}
                                    >
                                        {row.option}
                                    </option>
                                );
                            })}

                        </Form.Select>
                    </div>
                );
            } else if (elementSchema.type === 'radio') {
                return (
                    <div className="col-md-4 mb-3" key={elementSchema.label}>
                        <label class="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                        <br />
                        {elementSchema.options.map((row, i) => {
                            return (
                                <Form.Check
                                    key={i}
                                    inline
                                    type="radio"
                                    name={elementSchema.key}
                                    id={row.option}
                                    value={row.option}
                                    defaultChecked={customFieldData[elementSchema.key] === row.option}
                                    label={row.option}
                                    onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
                                />

                            );
                        })}

                    </div>
                );
            } else if (elementSchema.type === 'checkbox') {
                return (
                    <div className="col-md-4 mb-3" key={elementSchema.label}>
                        <div className="col-md-4 mb-3" key={elementSchema.label}>
                            <label class="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
                            <br />
                            {elementSchema.options.map((row, i) => {
                                return (
                                    <Form.Check
                                        key={i}
                                        inline
                                        type="checkbox"
                                        name={elementSchema.key}
                                        value={row.option}
                                        id={row.option}
                                        defaultChecked={customFieldData[elementSchema.key] && customFieldData[elementSchema.key].includes(row.option)}
                                        label={row.option}
                                        onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
                                    />

                                );
                            })}
                        </div>
                    </div>
                );
            } else if (elementSchema.type === 'date') {
                return (
                    <div className="col-md-4 mb-3" key={elementSchema.label}>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control
                                    type="date"
                                    data-date=""
                                    data-date-format="DD MMMM YYYY"
                                    defaultValue={customFieldData[elementSchema.name] ? customFieldData[elementSchema.name].toString().split("/").reverse().join("-") : ''}
                                    //defaultValue={customFieldData[elementSchema.key]}
                                    onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key} />
                            </div>
                        </Form.Group>
                    </div>
                );
            } else {
                return (
                    <div className="col-md-4 mb-3" key={elementSchema.label}>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control defaultValue={customFieldData[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} type="text" name={elementSchema.key} />
                            </div>
                        </Form.Group>
                    </div>
                );
            }

        })
    }

    const handleChangeCustomField = (type, e) => {
        const { name, value } = e.target;

        if (type === "checkbox") {
            if (customFieldData[name] && customFieldData[name].includes(value)) {
                setCustomFieldData((prevState) => ({
                    ...prevState,
                    [name]: prevState[name].filter((color) => color !== value),
                }));
            } else {
                setCustomFieldData((prevState) => ({
                    ...prevState,
                    [name]: [...(prevState[name] || []), value],
                }));
            }
        } else if (type === "date") {
            setCustomFieldData((prevState) => ({
                ...prevState,
                [name]: value.toString().split("-").reverse().join("/"),
            }));
        } else {
            setCustomFieldData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    const handleSubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        console.log(customField, 'customFieldcustomField')
        for (let [key, value] of formData.entries()) {
            if (customField && !customField.some(item => item.key === key) && key != 'category_id[]' && key != 'service_id[]' && key != 'tag_id[]') {
                submitData.append(key, value);
            } else if (!customField && key != 'category_id[]' && key != 'service_id[]' && key != 'tag_id[]') {
                submitData.append(key, value);
            }

        }
        submitData.append('category_id', formData.getAll('category_id[]'));
        submitData.append('service_id', formData.getAll('service_id[]'));
        submitData.append('tag_id', formData.getAll('tag_id[]'));
        const relatedIds = [];
        if (data.contact_type != 'new') {
            if (leadArrays.length == 0) {
                Swal.fire({
                    text: 'Kindly choose a contact record/related to from the provided list to proceed with the desired action.',
                    icon: 'warning',
                });
                setFormLoading(false);
                return false;
            } else {
                leadArrays.forEach(array => {
                    relatedIds.push(array.related_id);
                });
            }
        }
        submitData.append('related_id', relatedIds);
        console.log(customFieldData, 'customFieldData')
        submitData.append('custom_fields', JSON.stringify(customFieldData));
        sendRequest('jobs/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(-1);
                }
                setFormLoading(false);

            })
            .catch(error => {
                setFormLoading(false);
                console.error('POST request error:', error);
            });
    };
    const searchLead = (search) => {
        setData({ ...data, related_id: 0, lead_name: `${search}` });
        if (search != "") {
            const formData = new FormData();
            formData.append('filter_value', search);
            formData.append('type', 'search');
            sendRequest(`crm/leads/lists`, 'POST', formData).then(response => {
                if (response.status === true) {
                    setExistingLead(response.data.records);
                    if (response.data.records.length < 1) {
                    }
                }
            })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        } else {
            setExistingLead([]);
        }
    };
    return (
        <Layout title="Contacts" content="container-fluid">
            <div className="Job-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Jobs</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/jobs/lists">Job</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md border-0">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                {/* <Row>
                                                    <Col className="col-12 text-end">
                                                        <Button variant="primary" type="submit" className="btn-sm" disabled={formloading}>
                                                            {
                                                                formloading && (
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                )
                                                            }
                                                            <span>Create</span>
                                                        </Button>
                                                        <Button variant="danger" type="button"
                                                            className="btn-sm m-2"
                                                            onClick={() => props.navigate(-1)}
                                                        ><span>Cancel</span></Button>

                                                    </Col>
                                                </Row> */}
                                                <span className="border border-light rounded-1">
                                                    <Row className="g-3 my-2 mx-0">
                                                        <h4 class="bio-block-title">Basic Fields </h4>
                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="country_code">Pipeline</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={activeMasterId} onChange={(e) => { getSubPipeline(e.target.value); }} placeholder="Type" name="pipeline_id">
                                                                        <option value="">Select Pipeline</option>
                                                                        {meta.masterPipelines.map((row, i) => {
                                                                            return (
                                                                                <option key={i} value={row.id}>{row.title}</option>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="country_code">Milestone</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={activeStatusId} placeholder="Type" name="status_id">
                                                                        <option value="0">Select Milestone</option>
                                                                        {
                                                                            listsMileston.length > 0 && listsMileston.map((row, i) => {
                                                                                return (
                                                                                    <>
                                                                                        <optgroup label={row.title}>
                                                                                            {
                                                                                                row.pipeline_status.length > 0 && row.pipeline_status.map((status, k) => {
                                                                                                    return (
                                                                                                        <option key={k} value={status.id}>{status.title}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </optgroup>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="3">
                                                            <Form.Label>Amount</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text> <Icon name={'sign-dollar'} ></Icon> </InputGroup.Text>
                                                                <Form.Control min={1} defaultValue={data.amount} onChange={(e) => handleChange(e)} type="number" name="amount" placeholder="Amount" />
                                                            </InputGroup>
                                                        </Col>

                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Open Days Hours</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="number" name="open_days_hours" placeholder="Days/Hours Open" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Estimated Hours</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="number" name="estimated_hours" placeholder="Estimated Hours" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Hours</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="number" name="actual_hours" placeholder="Hours" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Due Date</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="date" name="due_date" placeholder="Due Date" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Job Site</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="text" name="job_site" placeholder="Job Site" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        {/* <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Lookup TIme Tracking</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="text" name="lookup_time" placeholder="Lookup TIme Tracking" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        {/* <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Site Address</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="text" name="site_address" placeholder="Site Address" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        {/* <Col md="3">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Billing Address</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control type="text" name="billing_address" placeholder="Billing Address" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="Phone">Description</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control as="textarea" name="description" placeholder="Description" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="role">Record Owner</Form.Label>
                                                                {meta?.coachPartners.length > 0 && (
                                                                    <Select name="coach_partner_id" defaultValue={meta?.coach_partner_params.coach_partner_id} removeItemButton>
                                                                        {
                                                                            meta?.coachPartners.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="role">Assign To</Form.Label>
                                                                {meta?.coachPartners.length > 0 && (
                                                                    <Select name="permissions[]" multiple defaultValue={meta?.coach_partner_params.coach_partner_id} removeItemButton>
                                                                        <option value="">Select</option>
                                                                        {
                                                                            meta?.coachPartners.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="4"></Col>

                                                        <Col md="12" className="mb-1">
                                                            <Row className="mb-1">
                                                                <Form.Control type="hidden" value={data.contact_type} name="contact_type" required />
                                                                {(uInfo().id && data.contact_type != 'new') ? (
                                                                    <>
                                                                        <Col md="4" className="mb-1">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="Phone">Related to (Contacts)</Form.Label>
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control type="text" size="sm" name="search_contact"
                                                                                        placeholder="Search Contact..."
                                                                                        value={data.lead_name}
                                                                                        onChange={(e) => searchLead(e.target.value)}
                                                                                        readOnly={query.has('related_id')}
                                                                                        onBlur={() =>
                                                                                            setTimeout(() => {
                                                                                                setExistingLead([]);
                                                                                            }, 1000)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </Form.Group>

                                                                            {existingLead.length > 0 && (
                                                                                <ListGroup className="my-1">
                                                                                    {existingLead.map((row, i) => {
                                                                                        return (
                                                                                            <ListGroup.Item key={i} style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                setData({ ...data, related_id: row.id, lead_name: `${row.id}# ${row.first_name} ${row.last_name}` })
                                                                                                addNewALeadrray(row.id, `${row.id}#${row.first_name} ${row.last_name}`);
                                                                                            }
                                                                                            } >
                                                                                                <MediaGroup>
                                                                                                    <MediaText>
                                                                                                        <div className="lead-text">{row.first_name} {row.last_name}</div>
                                                                                                        <span className="sub-text">{row.email}</span>
                                                                                                    </MediaText>
                                                                                                </MediaGroup>
                                                                                            </ListGroup.Item>
                                                                                        );
                                                                                    })}
                                                                                </ListGroup>
                                                                            )}
                                                                        </Col>
                                                                        <Col md="4" className="mb-1">
                                                                            <Form.Group className={`form-group ${query.has('related_id') && ('d-none')}`}>
                                                                                <Form.Label htmlFor="Phone"></Form.Label>
                                                                                <div className="form-control-wrap my-1">
                                                                                    <button type="button" onClick={() => setData({ ...data, contact_type: 'new' })} className="btn btn-sm btn-primary">Add new</button>
                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="border border-light rounded-1">
                                                                            <Row className="g-3 my-2 mx-0">
                                                                                <Row className="mt-2">
                                                                                    <Col>
                                                                                        <   h4 class="bio-block-title">Add new Contact </h4>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <button className="btn p-0" style={{ float: 'right' }} title="Back to existing lead" onClick={() => setData({ ...data, contact_type: 'existing' })}>
                                                                                            <Icon name="back-arrow" className='text-info'></Icon>
                                                                                        </button>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Col md="6" className="mb-1" >
                                                                                    <Form.Group className="form-group">
                                                                                        <Form.Label htmlFor="name">Name</Form.Label>
                                                                                        <div className="form-control-wrap">
                                                                                            <Form.Control type="text" size="sm" name="name" required />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                {/* <Col md="6" className="mb-1" >
                                                                                    <Form.Group className="form-group">
                                                                                        <Form.Label htmlFor="last_name">Last name</Form.Label>
                                                                                        <div className="form-control-wrap">
                                                                                            <Form.Control type="text" size="sm" name="last_name" required />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col> */}
                                                                                <Col md="6" className="mb-1">
                                                                                    <Form.Group className="form-group">
                                                                                        <Form.Label htmlFor="last_name">Email</Form.Label>
                                                                                        <div className="form-control-wrap">
                                                                                            <Form.Control type="email" size="sm" name="email" required />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6" className="mb-1">
                                                                                    <Form.Group className="form-group">
                                                                                        <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                                                        <div className="form-control-wrap">
                                                                                            <Form.Select placeholder="Country" size="sm" name="country_code">
                                                                                                {
                                                                                                    countries.map((row, i) => {
                                                                                                        return <option key={i} value={row.code}>{row.label}</option>
                                                                                                    })
                                                                                                }
                                                                                            </Form.Select>
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="6" className="mb-1">
                                                                                    <Form.Group className="form-group">
                                                                                        <Form.Label htmlFor="Phone">Phone</Form.Label>
                                                                                        <div className="form-control-wrap">
                                                                                            <Form.Control type="number" size="sm" name="phone" placeholder="Phone" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                {meta?.lead_source && (
                                                                                    <Col md="6" className="mb-1">
                                                                                        <Form.Group className="form-group">
                                                                                            <Form.Label htmlFor="country_code">Lead Source</Form.Label>
                                                                                            <div className="form-control-wrap">
                                                                                                <Form.Select onChange={(e) => handleChange(e)} placeholder="Type" name="lead_source">
                                                                                                    {Object.entries(meta?.lead_source).map(([key, value], i) => (
                                                                                                        <option key={i} value={key}>{value}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                )}
                                                                            </Row>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Row>
                                                            {(uInfo().id && data.contact_type != 'new') && (
                                                                <>
                                                                    <ul className="nk-list-option pt-0 w-100 text-start align-items-start">
                                                                        <li>
                                                                            <div className="mt-md-0 text-start">
                                                                                <ul className="d-flex flex-wrap gap gx-1">
                                                                                    {leadArrays.map((row, index) => (
                                                                                        <li key={index} onClick={() => unsetLeadArray(row.related_id)} style={{ cursor: 'pointer' }}>
                                                                                            <span className="badge text-bg-secondary-soft"><Icon name={'cross-circle-fill'}
                                                                                            ></Icon> {row.name}</span>
                                                                                        </li>

                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </>
                                                            )}
                                                        </Col>

                                                    </Row>
                                                </span>
                                                {meta && (
                                                    <span className="border border-light rounded-1 CategoryService">
                                                        <Row className="g-3 my-2 mx-0">
                                                            <h4 class="bio-block-title">Category, Service & Tag Fields</h4>
                                                            <Col md="4">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="category_id">{meta.tags_labels?.Category ?? 'Category'}</Form.Label>
                                                                    <div className="form-control-wrap">


                                                                        <Select name="category_id[]" multiple defaultValue={[defultCategory]} >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.categories && meta.categories.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>


                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md="4">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="service_id">{meta.tags_labels?.Service ?? 'Service'} </Form.Label>
                                                                    <div className="form-control-wrap">


                                                                        <Select name="service_id[]" multiple defaultValue={[defultService]} >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.services && meta.services.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md="4">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="tag_id">{meta.tags_labels?.Tag ?? 'Tag'}</Form.Label>
                                                                    <div className="form-control-wrap sm">
                                                                        <Select name="tag_id[]" multiple defaultValue={data.tag_id} >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.tags && meta.tags.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>


                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </span>
                                                )}
                                                <span className="border border-light rounded-1">
                                                    <Row className="m-3 mx-0">
                                                        <h4 class="bio-block-title">Custom Fields</h4>
                                                        {
                                                            meta.customFields && (
                                                                createUi()
                                                            )
                                                        }
                                                    </Row>
                                                </span>
                                                <Row className="mt-2 mx-0">
                                                    <Col className="col-12 justify-content-end d-flex px-0">
                                                        <Button variant="primary" type="submit" className="btn-sm" disabled={formloading}>
                                                            {
                                                                formloading && (
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                )
                                                            }
                                                            <span>Create</span>
                                                        </Button>
                                                    
                                                        <Button variant="danger" type="button"
                                                            className="btn-sm ms-2"
                                                            onClick={() => props.navigate(-1)}
                                                        ><span>Cancel</span></Button>

                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout >
    )
}

export default LeadCreate;