import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import { capitalizeFirstLetter, uInfo } from "../../../utilities";

function EditPagesCms(props) {
    const { id, type, action, proposal_id } = useParams();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRecordById();
    }, []);



    const fetchRecordById = () => {
        sendRequest(`cms/${type}/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (proposal_id) {
            submitData.append('proposal_id', proposal_id);
        }

        sendRequest(`cms/${type}/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(-1);
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="CMS" content="container-fluid">
            <div className="cms-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">{capitalizeFirstLetter(type)}</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={proposal_id ? `/cms-manage/${type}/lists/${proposal_id}` : `/cms-manage/${type}/lists`}>{capitalizeFirstLetter(type)}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control disabled={action == 'view' ? true : false} defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                <Col md="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">{(uInfo().type === 'Manager' || uInfo().type === 'SubManager') ? 'Type' : 'Subject'}</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control disabled={action == 'view' ? true : false} defaultValue={data.subject} type="text" name="subject" placeholder="Subject" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {action == 'view' ? (
                                                    <Col md={12}>
                                                        <Form.Label htmlFor="title">Body</Form.Label>
                                                        <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                                    </Col>
                                                ) : (
                                                    <Col md="12">
                                                        <Form.Group>
                                                            <Tinymce height={700} textareaName={'body'} toolbar={true} initialValue={data.body} />
                                                        </Form.Group>
                                                    </Col>
                                                )}



                                                {!(uInfo().type === 'Manager' || uInfo().type === 'SubManager') && (
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="phone">Status</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select disabled={action == 'view' ? true : false} defaultValue={data.status} type="text" name="status" placeholder="status" required >
                                                                    <option value="">Select Status</option>
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>

                                                                </Form.Select>


                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                )}

                                                {action == 'edit' && (
                                                    <Col className="col-12">
                                                        <Button className="btn-sm" type="submit">Update</Button>
                                                    </Col>
                                                )}

                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default EditPagesCms;